<template>
    <div class='organization-edit'>
        <Dialog :visible.sync="showCancelDialog" header="" position="center" :modal="true" :closeOnEscape="true" :closable="false">
            <div class="p-dialog-content p-ai-center p-d-flex">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"></i>
                <span>Вы вносили данные. Вы уверены, что хотите покинуть страницу без сохранения данных?</span>
            </div>

            <template #footer>
                <Button class="p-button p-button-text p-button-danger" @click="applyLeaveRoute">
                    <span class="pi pi-times p-button-icon p-button-icon-left"></span>
                    <span class="p-button-label">Удалить данные и покинуть</span>
                </Button>
                <Button class="p-button p-component p-button-text" type="button" autofocus @click="showCancelDialog = false">
                    <span class="pi pi-check p-button-icon-left"></span>
                    <span class="p-button-label">Вернуться к заполнению</span>
                    <span class="p-ink"></span>
                </Button>
            </template>
        </Dialog>
        <h5 class="p-d-inline p-pt-0 edit-form-title">{{ formTitle }}</h5>
        <TabView
            class='edit-form__tabs'
            :activeIndex.sync='activeTabIdx'
        >
            <TabPanel
                v-for='(tab, index) in accessibleItems(tabs)'
                :key='index'
                :header='tab.label'
                :disabled='tab.disabled'
                :active.sync='activeTabs[index]'
            >
                <component
                    v-if='activeTabs[index]'
                    :is='tab.component'
                    :edit-mode='isEditMode'
                    :org-id='orgId'
                    :main-org-id='mainOrgId'
                    @loadingChange='$emit("loadingChange", $event)'
                    @is-branch='isBranchHandler'
                    @change-data='changedDataHandler'
                />
            </TabPanel>
        </TabView>
    </div>
</template>

<script>
import Tags from '@/pages/organizations/organizationEditFormTabs/Tags';
import Forms from '@/pages/organizations/organizationEditFormTabs/Forms';
import Params from '@/pages/organizations/organizationEditFormTabs/Params';
import Branches from '@/pages/organizations/organizationEditFormTabs/Branches';
import Users from '@/pages/organizations/organizationEditFormTabs/Users';
import History from '@/pages/organizations/organizationEditFormTabs/History';
import Licenses from '@/pages/organizations/organizationEditFormTabs/Licenses';
import { ORG_FORM_TITLES } from '@/constants/organizations';
import { USER_PERMISSIONS_MAP } from '@/constants/common';
import { mapGetters } from 'vuex';

const {
    organizationReadAll,
    organizationWriteAll,
    organizationWriteOwn,
    organizationReadOwn,
    organizationWrite,
    organizationRead,
    formReadOwn,
    formWriteOwn,
    formWrite,
    formRead,
    userWriteOwn,
    userWriteAll,
    userReadAll
} = USER_PERMISSIONS_MAP

export default {
    name: 'OrgsNew',
    components: {
        Params,
        Branches,
        Users,
        Forms,
        Tags,
        History,
        Licenses
    },
    props: {
        orgId: {
            type: [String, Number],
            default: ''
        },
        mainOrgId: {
            type: [String, Number],
            default: ''
        }
    },
    activated() {
        if (this.dataLoaded) this.$emit('loadingChange')
    },
    data() {
        this.editTitle = ORG_FORM_TITLES.EDIT
        this.createTitle = ORG_FORM_TITLES.CREATE
        return {
            dataLoaded: false,
            activeTabIdx: 0,
            changedData: false,
            acceptAction: null,
            showCancelDialog: false,
            activeTabs: [true, false, false, false]
        }
    },

    computed: {
        ...mapGetters('auth', [
            'userPermissionsObject',
            'accessibleItems'
        ]),
        isHeadOrganization() {
            return this.formData.isHeadOrganization.value;
        },
        isEditMode() {
            return !!this.orgId
        },
        formTitle() {
            return this.isEditMode ? this.editTitle : this.createTitle
        },
        currentTab() {
            return this.tabs[this.activeTabIdx]
        },
        canEditOrg() {
            return [
                organizationWriteOwn,
                organizationWriteAll,
                organizationWrite
            ].some(p => this.userPermissionsObject[p])
        },
        tabs() {
            return [
                {
                    label: 'Параметры',
                    component: 'Params'
                },
                {
                    label: 'Формы',
                    component: 'Forms',
                    disabled: !this.orgId,
                    permissionAccess: [
                        formReadOwn,
                        formWriteOwn,
                        formRead,
                        formWrite
                    ]
                },
                {
                    label: 'Филиалы',
                    component: 'Branches',
                    disabled: !this.orgId || !!this.mainOrgId,
                    permissionAccess: [
                        organizationWriteAll,
                        organizationReadAll,
                        organizationWrite,
                        organizationRead
                    ]
                },
                {
                    label: 'Пользователи',
                    component: 'Users',
                    disabled: !this.orgId,
                    permissionAccess: [
                        userWriteOwn,
                        userReadAll,
                        userWriteAll
                    ]
                },
                {
                    label: 'Теги',
                    component: 'Tags',
                    disabled: !this.orgId,
                    permissionAccess: [
                        organizationWriteOwn,
                        organizationReadOwn,
                        organizationWriteAll,
                        organizationReadAll,
                        organizationWrite,
                        organizationRead
                    ]
                },
                {
                    label: 'История изменений',
                    component: 'History',
                    disabled: !this.orgId,
                    permissionAccess: []
                },
                {
                    label: 'Лицензии',
                    component: 'Licenses',
                    disabled: !this.orgId,
                    permissionAccess: []
                }
            ];
        }
    },

    methods: {
        changedDataHandler({ value, acceptAction }) {
            this.changedData = value
            this.acceptAction = acceptAction
        },

        applyLeaveRoute() {
            this.changedData = false
            this.$router.push(this.toFullPath)
        },

        isBranchHandler() {
            const branchesTab = this.tabs.find(({ component }) => component === 'Branches')
            branchesTab.disabled = true
        }
    },
    watch: {
        activeTabs: {
            handler() {
                if (this.changedData) {
                    this.$root.$emit('show-leave-dialog', {
                        acceptCb: async () => await this.acceptAction(),
                        beforeClose: () => {
                            this.changedData = false
                            this.acceptAction = null
                        }
                    })
                }
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.changedData) {
            this.$root.$emit('show-leave-dialog', {
                acceptCb: async () => await this.acceptAction(),
                beforeClose: next
            })
            next(false)
        } else {
            next()
        }
    }
};
</script>

<style scoped lang="scss">
.edit-form {
    &-title {
    }
}
.my-form {
    background-color: #f8f9fa;
}
label {
    display: block;
    padding-left: 0.1rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
    font-size: 12px;
}
.p-field-checkbox,
.p-field-radiobutton {
    label {
        padding-bottom: 0.1rem;
    }
}
.country-item {
    max-width: 500px;
}

</style>
