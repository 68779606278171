<template>
    <DataTable
        :value="licensesList"
        class="p-datatable-customers p-datatable-striped user-list"
        :scrollable="true"
        :paginator="true"
        :lazy="true"
        scroll-height="calc(100vh - 350px)"
        :rows="pageSize"
        :rowsPerPageOptions="rowsPerPageOptions"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate=""
        :totalRecords="totalRecords"
        dataKey="id"
        selectionMode="single"
        stripedRows
        resizable-columns
        @page="onPage"
        @sort="onSort"
    >
        <template #header>
            <div class="table-header p-flex-wrap p-d-flex">
                <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
                <div class="p-col-7 p-p-0 p-d-flex p-jc-end p-ai-center table-global-search">
                    <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                        <span class="p-float-label">
                            <InputText type="text" v-model="filters.work" placeholder="Поиск" @keydown="selectedFilter" />
                        </span>
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
            </div>
        </template>
        <template #empty>Лицензии не найдены.</template>
        <template #loading>Список лицензий загружается. Пожалуйста, подождите.</template>
        <Column
            header="Вид деятельности"
            field="work"
            header-class="user-header-work table-header"
            body-class="user-body-work table-body"
            :sortable="true"
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate" :title="slotProps.data.work">
                    {{ slotProps.data.work }}
                </div>
            </template>
        </Column>
        <Column
            header="Номер лицензии"
            header-class="user-header-number table-header"
            body-class="user-body-number table-body"
            field="number"
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate">{{ slotProps.data.number }}</div>
            </template>
        </Column>
        <Column
            header="Дата лицензии"
            field="date"
            header-class="user-header-date table-header"
            body-class="user-body-date table-body"
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate">{{ slotProps.data.date | date }}</div>
            </template>
        </Column>
        <Column
            header="Дата загрузки"
            field="createdAt"
            header-class="user-header-date table-header"
            body-class="user-body-date table-body"
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate">{{ slotProps.data.createdAt | date }}</div>
            </template>
        </Column>
        <template #paginatorLeft>
            <div class="p-d-flex"></div>
        </template>
    </DataTable>
</template>

<script>
import { requestToastHandler } from '@/main/mixins';
import { jsonApiListParser } from '@/main/utils/common';
import { mapGetters } from 'vuex';
import { getOrganizationLicenses } from '@/api/organization';
import { DEFAULT_PAGE_SIZE } from '@/constants/common';

export default {
    name: 'Licenses',

    mixins: [ requestToastHandler ],

    emits: [ 'loadingChange' ],

    props: {
        orgId: {
            type: String,
            require: true,
        }
    },

    data() {
        this.pageSize = DEFAULT_PAGE_SIZE;

        return {
            licensesList: [],
            totalRecords: null,
            currentPage: 1,
            filters: {},
            filterTimeout: null,
            sortField: ''
        };
    },

    async mounted() {
        await this.getLicenses();
    },

    methods: {
        onPage(event) {
            this.currentPage = event.page + 1;
            this.pageSize = event.rows;
            this.debouncedFilter();
        },

        onSort({ sortField, sortOrder }) {
            this.sortField = `${ sortOrder > 0 ? '' : '-' }${ sortField }`;
            this.debouncedFilter();
        },

        selectedFilter() {
            this.currentPage = 1;
            this.pageSize = DEFAULT_PAGE_SIZE;
            this.debouncedFilter();
        },

        debouncedFilter() {
            clearTimeout(this.filterTimeout);

            this.filterTimeout = setTimeout(() => {
                this.getLicenses();
            }, 1000);
        },

        async getLicenses() {
            this.$emit('loadingChange', true);

            let filter = {};
            if (this.filters.work && this.filters.work.length > 0) {
                filter['work'] = { '$like': this.filters.work };
            }

            try {
                const { data: { data, included, meta } } = await getOrganizationLicenses(this.orgId, {
                    page: this.currentPage,
                    pageSize: this.pageSize,
                    filter,
                    sort: this.sortField
                });
                this.licensesList = jsonApiListParser(data, included);
                this.totalRecords = meta.pagination.total;
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        }
    },

    computed: {
        ...mapGetters(['rowsPerPageOptions'])
    }
}
</script>

<style lang="scss" scoped>
.p-datatable {
    &-customers {
        ::v-deep {
            .p-paginator-bottom {
                border-width: 1px 0 0 0 !important;
            }

            .user-header {
                &-number {
                    width: 13vw;
                }

                &-date {
                    width: 12vw;
                }
            }

            .user-body {
                &-number {
                    width: 13vw;
                }

                &-date {
                    width: 12vw;
                }
            }
        }
    }
}
</style>
