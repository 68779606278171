import { axios, apiUrl } from '@/api/api.js'
import { PAGE_SIZE } from '@/constants/common';
import { generateErrors } from '@/main/utils/common';

export async function getOrganizationHistory(organizationId, { page = 1, pageSize = PAGE_SIZE }) {
    try {
        const params = {
            page,
            'page-size': pageSize,
            include: 'organization,parent,subordinateTo,location,district,type,createdBy'
        };
        const { data: result } = await axios.get(`${ apiUrl }/organization/${ organizationId }/relationships/history`, { params });

        return {
          data: result.data,
          included: result.included,
          meta: result.meta
        };
    } catch (error) {
        generateErrors(error);
    }
}
