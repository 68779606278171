<template>
    <div>
        <Dialog
            :visible.sync="showDialog"
            position="center"
            :modal="true"
            :closeOnEscape="true"
            :closable="true"
            class="form-list-dialog"
            :contentStyle="{ overflow: 'visible' }"
        >
            <template #header>Раздать форму</template>
            <div class='form-list-dialog__content'>
                <div class="p-field">
                    <label class='field-label'>Форма</label>
                    <Dropdown
                        :value='selectedForm.id'
                        :options='[selectedForm]'
                        option-value='id'
                        placeholder=''
                        option-label='name'
                        filter
                        disabled
                    ></Dropdown>
                </div>
                <div class="p-field">
                    <label class='field-label'>Организации*</label>
                    <MultiSelect26
                        v-model='formData.organizations'
                        :options='[organization]'
                        option-value='id'
                        placeholder='Выберите из списка организаций'
                        option-label='shortName'
                        dataKey='id'
                        display="chip"
                        filter
                        disabled
                    />
                    <small v-show="submitted" v-for="error in showErrors(v$.formData.organizations)" :key="error.$uid" class="p-error">
                        <small>{{ error.$message }}</small><br>
                    </small>
                </div>
                <div class="p-field">
                    <label class='field-label'>Период*</label>
                    <MultiSelect26
                        v-model='formData.periods'
                        :options='formPeriods'
                        data-key='id'
                        option-value='id'
                        placeholder='Выберите из списка периодов'
                        option-label='name'
                        display="chip"
                        filter
                    />
                    <small v-for="error in showErrors(v$.formData.periods)" :key="error.$uid" class="p-error">
                        <small>{{ error.$message }}</small><br>
                    </small>
                </div>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button
                        :disabled='disabledDialogSaveBtn'
                        class="p-button"
                        type="button"
                        @click='sendFormHandler'
                    >
                        <span class="p-button-label">Раздать форму</span>
                        <span class="p-ink"></span>
                    </Button>
                    <Button @click='showDialog = false' class="p-button p-button-outlined">
                        <span class="p-button-label black"> Отменить </span>
                    </Button>
                </div>
            </template>
        </Dialog>
        <DataTable
            :value="forms"
            :paginator="true"
            class="p-datatable-customers p-datatable-striped form-list"
            :class="{ showFilters: showFilters }"
            :rows="20"
            :rowsPerPageOptions="rowsPerPageOptions"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate=""
            :totalRecords="totalRecords"
            dataKey="id"
            selectionMode="single"
            :filters="filters"
            :scrollable="true"
            scroll-height="calc(100vh - 400px)"
            resizable-columns
        >
            <!-- //:lazy="true"
            @page="paginationChange($event)" -->
            <template #header>
                <div class="table-header p-flex-wrap p-d-flex">
                    <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-jc-end table-global-search">
                        <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                            <span class="p-float-label">
                                <InputText type="text" v-model="filters['global']" placeholder="Поиск" />
                            </span>
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-search"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty> Формы не найдены</template>
            <template #loading> Загружается список форм. Пожалуйста подождите</template>
            <Column header="Наименование" field="name" :sortable="true" header-class="header-name" body-class="body-name">
                <template #body="slotProps">
                    <div :title="slotProps.data.name" class="p-text-nowrap p-text-truncate">{{ slotProps.data.name }}</div>
                </template>
                <template v-if="showFilters" #filter>
                    <InputText v-show="showFilters" type="text" v-model="filters['name']" class="p-column-filter p-my-2" placeholder="Наименование" />
                </template>
            </Column>
            <Column header="Тип" field="type" :sortable="true" header-class="header-type" body-class="body-type">
                <template #body="slotProps">
                    {{ formTypes[slotProps.data.type] }}
                </template>
                <template v-if="showFilters" #filter>
                    <Dropdown
                        v-model="filters['type']"
                        :options="formTypesFilters"
                        option-value='value'
                        option-label='label'
                        placeholder="Тип"
                        class="p-column-filter p-my-2"
                        :showClear="true"
                    />
                </template>
            </Column>
            <Column header="Периодичность" field="distributionInterval.name" filter-field='distributionInterval.id' :sortable="true" header-class="header-periods" body-class="body-periods">
                <template #body="slotProps">
                    {{ slotProps.data.distributionInterval.name.toLowerCase() }}
                </template>
                <template v-if="showFilters" #filter>
                    <Dropdown
                        v-model="filters['distributionInterval.id']"
                        data-key="id"
                        :options="formPeriodsFilters"
                        option-label='label'
                        option-value='id'
                        placeholder="Периодичность"
                        class="p-column-filter p-my-2"
                        :showClear="true"
                        @click.stop
                    >
                    </Dropdown>
                </template>
            </Column>
            <Column header="Статус" field="status" :sortable="true" header-class="header-status">
                <template #body="slotProps">
                    <span :style="`background: ${statusColors[slotProps.data.status]}; padding: 4px; border-radius: 4px;`">
                        {{ slotProps.data.status }}
                    </span>
                </template>
                <template v-if="showFilters" #filter>
                    <Dropdown v-model="filters['status']" :options="formStatusesFilters" placeholder="Статус" class="p-column-filter p-my-2" :showClear="true" @click.stop>
                        <template #option="slotProps">
                            <span :class="'customer-badge status-' + slotProps.option">{{ slotProps.option }}</span>
                        </template>
                    </Dropdown>
                </template>
            </Column>
            <Column header="" header-class="p-text-center" body-style="position: relative; width: 52px; text-align: center;" header-style="width: 52px;">
                <template v-if='accessibleItems(listMenuItems).length && !isBranch' #body="slotProps">
                    <div class="p-panel-header-icon p-link" @click.stop="toggleRowMenu($event, slotProps)">
                        <span class="pi pi-ellipsis-h"></span>
                    </div>
                    <Menu
                        class="redLastListElement"
                        :ref="`listMenu${slotProps.data.id}`"
                        :model="accessibleItems(listMenuItems)"
                        :popup="true"
                        :baseZIndex="10"
                        :class='getDisabledClass(slotProps.data.status)'
                    />
                    <!--                <Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text" @click.stop="editRole(slotProps.data.id)" />-->
                    <!--                <Button v-if="!guardRoles.find((role) => slotProps.data.id == role)" icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text p-ml-1" @click="removeRole(slotProps.data.id)" />-->
                </template>

                <template #header>
                    <Button icon="pi pi-filter" :class="filterClasses" class="p-button-rounded p-button-outlined filter-btn" @click="showFilters = !showFilters" />
                </template>
            </Column>
            <template #paginatorLeft />
        </DataTable>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import {
    createNewFormsdistribute,
    editFormParametrs,
} from '@/api/form';
import { mapGetters } from 'vuex';
import { requestToastHandler } from '@/main/mixins';
import { jsonApiListParser } from '@/main/utils/common';
import {
    FORM_STATUSES_COLORS,
    FORM_STATUSES_NAMES,
    FORM_TYPES,
    FORM_STATUSES,
} from '@/constants/forms';
import { getAllIntervalPeriods } from '@/api/form/formsPeriods';
import { deleteFormAssignments } from '@/api/form/formAssignments';
import SendForm from '@/models/SendForm';
import { getOrganizationAssignments, getOrganizationById, getOrganizations } from '@/api/organization';
import SubmitJsonApi from '@/models/SubmitJsonApi';
import { minLength } from '@vuelidate/validators';
import Organization from '@/models/Organization';
import { ORG_FIELDS } from '@/constants/organizations';
import { USER_PERMISSIONS_MAP, DEFAULT_PAGE_SIZE } from '@/constants/common';

const { ACTIVE, ARCHIVE, DRAFT } = FORM_STATUSES_NAMES;

const {
    formWrite
} = USER_PERMISSIONS_MAP

export default {
    name: 'Forms',
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
        orgId: {
            type: [String, Number],
            default: ''
        }
    },

    emits: ['loadingChange'],
    mixins: [requestToastHandler],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        this.statusColors = FORM_STATUSES_COLORS;
        this.formStatusesFilters = FORM_STATUSES;
        this.formTypesFilters = Object.values(FORM_TYPES);
        this.formTypes = {}
        Object.values(FORM_TYPES).forEach(i => {
            this.formTypes[i.value] = i.label
        })
        this.pageSize = DEFAULT_PAGE_SIZE;

        return {
            organization: new Organization({ id: this.orgId }),
            loadingOrgs: false,
            currentPage: 1,
            totalRecords: null,
            formPeriods: [],
            assignments: [],
            assignmentOrgs: [],
            searchInputOrgs: '',
            filterInputTimeout: null,
            submitted: false,
            formData: new SendForm() || {},
            formPeriodsFilters: [],
            showDialog: false,
            editItemId: null,
            filters: {},
            showFilters: false,
            // loading: true,
            forms: [],
            listMenuItems: [
                {
                    label: 'Раздать форму',
                    icon: 'pi pi-angle-double-down',
                    class: 'send-form',
                    command: () => {
                        this.openDialogHandler();
                    },
                    permissionAccess: [
                        formWrite
                    ]
                },
                // {
                //     label: 'Сделать черновиком',
                //     icon: 'pi empty',
                //     class: 'draft',
                //     command: () => {
                //         this.changeStatus(DRAFT)
                //     },
                // },
                // {
                //     label: 'Сделать архивной',
                //     icon: 'pi empty',
                //     class: 'archive',
                //     command: () => {
                //         this.changeStatus(ARCHIVE)
                //     },
                // },
                // {
                //     label: 'Сделать активной',
                //     icon: 'pi empty',
                //     class: 'active',
                //     command: () => {
                //         this.changeStatus(ACTIVE)
                //     },
                // },
                {
                    label: 'Удалить назначение',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.$root.$emit('showAcceptDeleteDialog', {
                            acceptAction: () => this.removeAssignment()
                        })
                    },
                    class: 'remove-row',
                    permissionAccess: [
                        formWrite
                    ]
                },
            ],
        };
    },
    validations() {
        return {
            formData: {
                organizations: {},
                periods: {
                    minLength: { ...minLength(1) }
                }
            }
        }
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        ...mapGetters('auth', [
            'accessibleItems'
        ]),
        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },
        selectedForm() {
            return this.forms.find((item) => item.id === this.editItemId) || { id: '' }
        },
        disabledDialogSaveBtn() {
            const { organizations, periods } = this.formData || {}
            return !organizations.length || !periods.length
        },
        isBranch() {
            return !!this.organization?.parent?.id
        }
    },
    methods: {
        async fetchOrg() {
            const {
                data: { attributes: orgData, relationships },
            } = await getOrganizationById(this.orgId);
            ORG_FIELDS.forEach((key) => {
                this.organization[key] = orgData[key] || '';
            });
            Object.keys(relationships).forEach((prop) => {
                const propData = relationships[prop].data;
                if (propData && !Array.isArray(propData)) {
                    this.organization[prop].id = propData.id;
                }
            });
            this.organization.active = orgData.active;
            this.formData.organizations = [this.organization.id]
        },
        getDisabledClass(itemStatus) {
            const classMap = {
                [ACTIVE]: 'disabled-active',
                [DRAFT]: 'disabled-draft',
                [ARCHIVE]: 'disabled-archive'
            }
            return classMap[itemStatus]
        },
        async changeStatus(newStatus) {
            if (this.selectedForm.status === newStatus) {
                return
            }
            const statusFieldsMap = {
                [ARCHIVE]: { archive: true, active: false },
                [ACTIVE]: { archive: false, active: true },
                [DRAFT]: { archive: false, active: false }
            }
            const distInterval = {
                id: this.selectedForm.distributionInterval.id,
                type: 'form-distribution-interval'
            }
            const data = {
                ...this.selectedForm,
                ...statusFieldsMap[newStatus],
                id: this.editItemId,
                distributionInterval: distInterval
            }
            try {
                this.$emit('loadingChange', true)
                await editFormParametrs(this.editItemId, new SubmitJsonApi(data, 'form'))
                const formIdx = this.forms.findIndex(({ id }) => id === this.editItemId)
                this.$set(this.forms, formIdx, { ...this.selectedForm, ...statusFieldsMap[newStatus], status: newStatus })
            } catch (error) {
                this.$requestError(error.message)
            } finally {
                this.$emit('loadingChange', false)
            }
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
        async openDialogHandler() {
            if (this.selectedForm.status !== ACTIVE) return
            this.showDialog = true;
            this.$emit('loadingChange', true)
            const { data: allIntervalPeriods, included: incPeriods } = await getAllIntervalPeriods({
                intervalId: this.selectedForm.distributionInterval.id,
                filter: {
                    active: {
                        '$eq': true
                    }
                }
            })
            this.formPeriods = jsonApiListParser(allIntervalPeriods, incPeriods)
            this.$emit('loadingChange', false)
        },
        async fetchOrgs() {
            try {
                const { organizations, included, meta } = await getOrganizations({
                    page: this.currentPage,
                    pageSize: this.pageSize
                })
                this.assignmentOrgs.push(...jsonApiListParser(organizations, included))
                this.totalRecords = meta.pagination.total;
            } catch (error) {
                throw new Error(error.message)
            }
        },
        async sendFormHandler() {
            try {
                this.$emit('loadingChange', true)
                const orgs = this.formData.organizations.map(i => ({ id: i, type: 'organization' }))
                const periods = this.formData.periods.map(i => ({ id: i, type: 'period' }))
                // const assignments = orgs.map(i => {
                //     const { data } = new SubmitJsonApi({ organization: i, year: '' }, 'form-assignment');
                //     return data
                // })
                // const res1 = await createFormAssignments(this.editItemId, { data: assignments })
                await createNewFormsdistribute(this.editItemId, {
                    data: {
                        organizations: {
                            data: orgs
                        },
                        periods: {
                            data: periods
                        }
                    }
                })
                this.showDialog = false
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange', false)
            }
        },
        editItem() {
            this.$router.push(`/forms/edit/${ this.editItemId }`);
        },
        rowClicked({ data }) {
            this.editItemId = data.id;
            this.editItem();
        },
        toggleRowMenu(event, slotProps) {
            const {
                data: { id },
            } = slotProps;
            // Close previous popup
            if (id !== this.editItemId) {
                this.$refs[`listMenu${this.editItemId}`] && this.$refs[`listMenu${this.editItemId}`].hide(event);
            }
            this.editItemId = id;
            // Open new popup
            this.$refs[`listMenu${id}`].toggle(event);
        },
        async getAllForms() {
            const { data: assignments, included: incOrgAssignments } = await getOrganizationAssignments({
                organizationId: this.orgId,
                include: 'form,form.distributionInterval,organization'
            })
            const forms = (incOrgAssignments || []).filter(i => i.type === 'form')
            const allIntervals = (incOrgAssignments || []).filter(i => i.type === 'form-distribution-interval')
            this.forms = jsonApiListParser(forms, allIntervals)
            this.assignments = jsonApiListParser(assignments, incOrgAssignments);
            this.forms = this.forms.map((form) => {
                form.status = form.archive ? ARCHIVE : DRAFT;
                if (form.active) form.status = ACTIVE;
                return form;
            });
            this.formPeriodsFilters = jsonApiListParser(allIntervals, []).map((i) => {
                return { label: i.name, id: i.id };
            });

        },

        debouncedInputFilter ({ value }) {
            clearTimeout(this.filterInputTimeout)
            this.filterInputTimeout = setTimeout(() => {
                console.log({ value });
                this.updateOrgList(value)
                // this.setLimitsChanged(true)
                // const balanceProp = getBalanceName(limitProp)
                // const item = this.limits[itemIdx]
                // const validatedVal = this.validateValueString(formatValue(val))
                // this.updateLimitItem({
                //     itemIdx,
                //     prop: limitProp,
                //     val: validatedVal
                // })
                // this.updateLimitItem({
                //     itemIdx,
                //     prop: balanceProp,
                //     val: calculateBalance(validatedVal, item[balanceProp].balance)
                // })
                // this.reCalculateRow([limitProp, balanceProp], itemIdx)
                // this.reCalculateRow([limitProp, balanceProp], 0)
            }, 500)
        },
        async updateOrgList(val) {
            if (this.loadingOrgs) return
            if (val) {
                if (val.length < 3) return
                try {
                    this.loadingOrgs = true
                    this.$emit('loadingChange', true)
                    const { organizations, included } = await getOrganizations({

                        filter: {
                            nameFull: { '$ilike': val }
                        }
                    })
                    const orgs = jsonApiListParser(organizations, included)
                    console.log({ orgs });
                    if (this.formData.organizations.length) {
                        const selectedOrgs = this.formData.organizations.map(id => this.assignmentOrgs.find(i => i.id === id))
                        this.assignmentOrgs = [...orgs, ...selectedOrgs]
                    } else {
                        this.assignmentOrgs = orgs
                    }
                } catch (e) {
                    console.log(e);
                } finally {
                    this.$emit('loadingChange', false)
                    this.loadingOrgs = false
                }
                return
            } else {
                this.assignmentOrgs = this.formData.organizations.map(id => this.assignmentOrgs.find(i => i.id === id))
            }

        },
        async removeAssignment() {
            try {
                this.$emit('loadingChange', true);
                const assignment = this.assignments.find(i => i.form.id === this.selectedForm.id)
                const data = {
                    id: assignment.id,
                    type: 'form-assignment',
                    relationships: {
                        form: {
                            data: {
                                id: assignment.form.id,
                                type: 'form'
                            }
                        },
                        organization: {
                            data: {
                                id: assignment.organization.id,
                                type: 'organization'
                            }
                        }
                    }
                };
                const response = await deleteFormAssignments(this.selectedForm.id, { data: [data] });
                if (response.message) {
                    this.$requestError(response.message);
                    return;
                }
                this.forms = this.forms.filter((form) => form.id !== this.selectedForm.id);
                this.$emit('loadingChange', false);
                // this.$toast.add({
                //     severity: 'success',
                //     summary: 'Организация удалена',
                //     life: 2500,
                // });
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange', false);
            }
        },
        // Пока не исполльзуем
        // removeForm (id) {
        //   this.forms = this.forms.filter((form) => form.id !== id);
        // },
    },
    watch: {
        searchInputOrgs: {
            handler(to) {
                this.updateOrgList(to)
            }
        },
        showDialog: {
            handler(to) {
                if (!to) {
                    this.formData = new SendForm()
                    this.formData.organizations = [this.organization.id]
                    this.currentPage = 1
                    this.formPeriods = []
                }
            }
        },
        filters: {
            deep: true,
            handler (to) {
                console.log({ to });
            }
        }
    },

    async created() {
        // if (this.forms) this.$emit('loadingChange');
        try {
            this.$emit('loadingChange', true);
            // await getFormDistribution({ filter: {
            //     'organization.id': {
            //         $eq: this.orgId
            //     }
            //     }})
            await this.fetchOrg()
            await this.getAllForms();
        } catch (error) {
            this.$requestError(error.message)
        } finally {
            this.$emit('loadingChange', false);
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .p-multiselect {
        height: unset!important;
    }
    .table-global-search {
        margin-top: 0!important;
    }
    .disabled {
        &-draft {
            .draft, .send-form {
                a {
                    opacity: .6;
                }
            }
        }
        &-archive {
            .archive, .send-form {
                a {
                    opacity: .6;
                }
            }
        }
        &-active {
            .active {
                a {
                    opacity: .6;
                }
            }
        }
    }
    .p-field {
        .p-dropdown {
            &.p-disabled {
                background: #F5F5F5;
            }
        }
    }
    .p-multiselect-token {
        background: var(--primary-color);
        height: 24px;
        width: 120px;
        padding: 4px 8px;
        border-radius: 3px;
        color: white;
        &-label {
            max-width: 88px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .form-list {
        &-dialog {
            width: 480px;
            .p-dialog {
                &-header {
                    padding: 24px 24px 0 24px;
                    font-size: 18px;
                }
                &-content {
                    padding: 24px 24px 0 24px;
                }
                &-footer {
                    padding: 24px 24px 24px 24px;
                }
            }
            .p-button {
                height: 34px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        ::v-deep {
            $col-padding-right: 3.06640625vw;
            .header,
            .body {
                &-name {
                    width: calc(34.21875vw + #{$col-padding-right});
                }
                &-type {
                    width: calc(9.375vw + #{$col-padding-right});
                }
                &-periods {
                    width: calc(10.28125vw + #{$col-padding-right});
                }
            }

            tbody,
            thead {
                tr {
                    td,
                    th {
                        &:not(:first-child) {
                            padding-left: 0 !important;
                        }
                        &:not(:last-child) {
                            padding-right: $col-padding-right !important;
                        }
                    }
                }
            }
        }
    }
}
</style>
