<template>
    <div>
        <DataTable
            :value="allTags"
            :paginator="true"
            class="p-datatable-customers p-datatable-striped"
            :class="{ showFilters: showFilters }"
            :rowsPerPageOptions="rowsPerPageOptions"
            :rows="pageSize"
            :scrollable="true"
            scroll-height="calc(100vh - 350px)"
            :totalRecords="totalRecords"
            dataKey="id"
            selectionMode="single"
            :filters="filters"
            stripedRows
        >
            <template #header>
                <div class="table-header p-d-flex p-jc-md-between p-flex-wrap">
                    <div class="p-col-6 p-p-0 p-d-flex p-ai-center p-mt-0">
                    </div>
                    <div class="p-col-6 p-p-0 p-d-flex p-ai-center">
                        <div class="p-inputgroup p-p-0 p-d-flex">
                            <span class="p-float-label">
                                <InputText type="text" v-model="filters['global']" placeholder="Поиск" />
                            </span>
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-search"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty> Тегов не найдено.</template>
            <template #loading> Загрузка списка тегов. Пожалуйста, подождите. </template>
            <Column header="Наименование" header-class="tag-name-header table-column" body-class="tag-name-body" body-style="max-height: 44px;" field="name" :sortable="true">
                <template #body="slotProps">
                    <div class="p-text-nowrap p-text-truncate">{{ slotProps.data.name }}</div>
                </template>
            </Column>
            <Column
                header-class='tag-checkbox-header'
                body-class='tag-checkbox-body'
                column-key="id"
            >
                <template #header>
                    <Checkbox v-model="allTagsProp" :disabled="!canEditOrg" binary />
                </template>
                <template #body="slotProps">
                    <Checkbox v-model="selectedTags" :key="`tag-checkbox${ slotProps.data.name }`" :value="slotProps.data.name" :disabled="!canEditOrg" />
                </template>
            </Column>
            <Column header="" body-style="position: relative; width: 52px; text-align: center;max-height: 44px;" header-style="width: 52px;">
            </Column>
            <template #paginatorLeft>
                <div class="p-col-6"></div>
            </template>
        </DataTable>
        <div v-if='canEditOrg' class='edit-form__actions'>
            <Button class="p-button p-mr-3 p-mt-4" label="Сохранить" @click="onSubmit"/>
            <Button class="p-button-outlined p-mr-2 p-mt-4" label="Отменить" @click='cancelAction'/>
        </div>
    </div>
</template>

<script>
import {
    getOrgTagsByOrgId,
    saveOrganizationTags,
    patchDirectoryTagList,
    getAllTags,
    unbindOrganizationTags,
} from '@/api/organization/orgsTags';
import {mapGetters} from 'vuex';
import {requestToastHandler} from '@/main/mixins';
import {useVuelidate} from '@vuelidate/core';
import {jsonApiListParser} from '@/main/utils/common';
import SubmitJsonApi from '@/models/SubmitJsonApi';
import { USER_PERMISSIONS_MAP, DEFAULT_PAGE_SIZE } from '@/constants/common';

const {
    organizationWrite,
    organizationWriteOwn,
    organizationWriteAll
} = USER_PERMISSIONS_MAP

export default {
    name: 'Tags',
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
        orgId: {
            type: [String, Number],
            default: ''
        }

    },
    emits: ['loadingChange'],
    mixins: [requestToastHandler],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        this.pageSize = DEFAULT_PAGE_SIZE;

        return {
            selectedTags: [],
            seleectedTagsIds: [],
            orgTags: [],
            allTags: [],
            filters: {},
            showFilters: false,
            currentPage: 1,
            totalRecords: 0,
            deleteTagDialogShow: false,
            dialogMode: '',
            filteredYears: [],
            currentTagId: null,
            currentTagStatus: null,
            currentTag: null,
            addCollectionElementDialog: false,
            newCollectionElementName: '',
            addCollectionElementDialogLoading: false,
            listMenuItems: [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.openAddCollectionDialog('Редактировать');
                    },
                },
                {
                    label: 'Удалить',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.showDeletePopUp();
                    },
                    class: 'remove-row',
                },
            ],
            submitted: false,
        };
    },
    // validations() {
    //     return {
    //         newCollectionElementName: {
    //             required: {
    //                 ...required,
    //                 $message: 'Поле обязательно к заполнению'
    //             }
    //         }
    //     }
    // },
    methods: {
        async onSubmit() {
            this.$emit('loadingChange', true);
            try {
                if (this.selectedTags?.length) {
                    await saveOrganizationTags(this.orgId, {
                        data: this.selectedTags?.map((item) => {
                                return {
                                    type: 'organization-tag',
                                    id: null,
                                    attributes: {
                                        tag: item
                                    }
                                };
                            })
                        }
                    );
                }
                if (this.forRemoveTags.length) {
                    const removeTags = this.forRemoveTags.map(i => new SubmitJsonApi(i, 'organization-tag', i.id).data)
                    await unbindOrganizationTags(this.orgId, { data: removeTags })
                }
            } catch (error) {
                this.$requestError(error.message)
            } finally {
                this.$emit('loadingChange');
            }
        },
        cancelAction() {
            this.selectedTags = this.orgTags.map(i => i.tag)
        },
        openAddCollectionDialog(mode) {
            this.submitted = false;
            this.dialogMode = mode;
            console.log('mode', mode);
            if (mode == 'Добавить') this.newCollectionElementName = '';
            this.addCollectionElementDialog = true;
        },
        showDeletePopUp() {
            this.deleteTagDialogShow = true;
        },
        hideDeletePopUp() {
            this.deleteTagDialogShow = false;
            this.currentTagId = null;
            this.currentTag = null;
        },
        closeDialog() {
            this.dialogMode = '';
            this.newCollectionElementName = '';
            this.currentTagId = null;
            this.currentTagStatus = null;
            this.currentTag = null;
            this.addCollectionElementDialog = false;
        },
        showTagMenu(event, TagId, active) {
            if (TagId !== this.currentTagId) {
                Object.keys(this.$refs).map((key) => {
                    this.$refs[key] && this.$refs[key].hide(event);
                });
            }
            this.currentTagId = TagId;
            this.currentTagStatus = active;
            this.currentTag = this.allTags.find((tags) => tags.id === TagId);
            this.newCollectionElementName = this.currentTag.name;
            this.$refs[`listMenu${TagId}`].toggle(event);
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
        async getData() {
            this.$emit('loadingChange', true);
            try {
                const { data } = await getAllTags();
                const result = jsonApiListParser(data).shift().tags;
                this.allTags = result.map((tag) => {
                    return {
                        name: tag,
                    };
                });
                const { data: orgTags } = await getOrgTagsByOrgId(this.orgId);
                this.selectedTagIds = this.orgTags = jsonApiListParser(orgTags).filter(({ tag }) => this.allTags.map(i => i.name).includes(tag));
                this.selectedTagIds.forEach((tag) => {
                    this.selectedTags.push(tag.tag);
                });
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        async createTag() {
            this.submitted = true;

            if (!this.v$.$invalid) {
                this.addCollectionElementDialog = false;
                const newOrgsTags = this.allTags.map((tagObj) => {
                    return tagObj.name;
                });
                newOrgsTags.push(this.newCollectionElementName);
                this.closeDialog();

                try {
                    this.$emit('loadingChange', true);
                    await this.patchDirectoryList(newOrgsTags);
                    this.$emit('loadingChange', false);
                } catch (error) {
                    this.$requestError(error.message);
                    throw new Error(error.message)
                }
            }
        },
        async patchTag(newOrgsTag) {
            this.submitted = true;

            if (!this.v$.$invalid) {
                this.$emit('loadingChange', true);
                try {
                    this.addCollectionElementDialog = false;
                    const newOrgsTags = this.allTags.map((tagObj) => {
                        return tagObj.id == newOrgsTag.id ? this.newCollectionElementName : tagObj.name;
                    });
                    await this.patchDirectoryList(newOrgsTags);
                    this.closeDialog();
                    this.$emit('loadingChange', false);
                } catch (error) {
                    this.$requestError(error.message);
                    this.$emit('loadingChange', false);
                    throw new Error(error.message)
                }
            }
        },
        async removeTag(tag) {
            this.addCollectionElementDialog = false;
            // const index = this.allTags.findIndex((orgsTag) => orgsTag === tag);
            try {
                this.$emit('loadingChange', true);
                const newOrgsTags = this.allTags
                    .filter((tagObj) => tagObj.id !== tag.id)
                    .map((tagObj) => {
                        return tagObj.name;
                    });
                await this.patchDirectoryList(newOrgsTags);
                this.$emit('loadingChange', false);
                // this.$toast.add({
                //     severity: 'success',
                //     summary: 'Тег успешно удален',
                //     life: '3200',
                // });
                this.hideDeletePopUp();
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange', false);
            }
        },
        async patchDirectoryList(newOrgsTags) {
            const dataToServer = {
                data: {
                    type: 'organization-tags',
                    id: '1',
                    attributes: {
                        tags: newOrgsTags,
                    },
                },
            };

            try {
                await patchDirectoryTagList('1', dataToServer);

                this.allTags = newOrgsTags.map((tag) => {
                    return {
                        id: Date.now() + Math.random(),
                        name: tag,
                        status: 'активный',
                    };
                });
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        sortArray(array) {
            return array.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            });
        }
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        ...mapGetters('auth', ['userPermissionsObject']),
        forRemoveTags () {
            if (!this.selectedTags.length) return this.orgTags
            return this.orgTags.filter(i => !this.selectedTags.includes(i.tag))
        },
        allTagsProp: {
            get() {
                return this.allTagsSelected;
            },
            set(v) {
                if (v) this.selectedTags = this.allTags.map(i => i.name);
                else this.selectedTags = []
            }
        },
        allTagsSelected () {
            return this.selectedTags.length === this.allTags.length;
        },
        filterClasses() {
            return this.showFilters ? 'mainBackground' : '';
        },
        canEditOrg() {
            return [organizationWriteAll, organizationWrite, organizationWriteOwn].some(p => this.userPermissionsObject[p])
        }
    },

    async created() {
        await this.getData();
    }
};
</script>

<style lang="scss" scoped>
.autocompleteWidth {
    width: 100px;
    ::v-deep {
        .p-button {
            color: #8794a3;
            background: white !important;
            border: 1px solid #eaeaea;
            border-left: none;
        }
        input {
            border-right: none;
        }
    }
}
.status {
    border-radius: 4px;
    padding: 4px;
    max-width: 64px;
    color: #272727;
    font-size: 13px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    // margin: 0 auto;
}
.activeStatus {
    background-color: #a1e6c3;
}
.noActiveStatus {
    background-color: #d7d7d7;
    max-width: 78px;
}

.customTitle {
    font-size: 17px;
}
::v-deep .p-datatable-header {
    padding-top: 0px;
    border-top: none;
    padding-left: 0;
    .p-inputtext:enabled:hover {
        border-color: #eaeaea;
    }
    .p-autocomplete .p-component .p-inputwrapper .p-autocomplete-dd .p-inputwrapper-filled {
        .p-button {
            background: white;
        }
    }
}
::v-deep .p-datatable {
    .p-datatable-tbody > tr > td {
        border: none;
    }
}
// ::v-deep .redLastListElement {
//     top: 10px !important;
//     right: 10px !important;
//     left: -9% !important;
//     li:last-child {
//         span {
//             color: red !important;
//         }
//     }
// }
::v-deep .customDialog {
    width: 480px;
}
::v-deep .p-inputgroup-addon {
    background: inherit;
    border-left: none;
}
::v-deep .p-inputgroup {
    .p-float-label {
        input {
            border-right: none;
        }
    }
}

::v-deep .p-datatable-thead {
    position: relative;

    // .mainBackground {
    //     background-color: #388e3c;
    //     color: white;
    //     outline: none;
    //     border: none;
    // }

    tr:last-child {
        th:last-child {
            text-align: right;
        }
    }
    .p-filter-column {
        padding: 0;
        background-color: #fff !important;
        input {
            min-width: 98%;
            max-width: 98%;
            margin: 5px;
        }
        .p-dropdown-clearable {
            max-width: 98%;
        }
    }
}
.p-datatable {
    &-customers {
        ::v-deep {
            .table-column {
                padding-right: 3.411vw;
            }

            .tag-name {
                &-header {
                    width: 50.1875vw;
                }
                &-body {
                    width: 50.1875vw;
                }
            }

            .tag-status {
                &-header {
                    width: 16.484375vw;
                }
                &-body {
                    width: 16.484375vw;
                    text-align: left;
                }
            }

            .tag-checkbox {
                &-header {
                    width: 7vw;
                }
                &-body {
                    width: 7vw;
                }
            }

            .p-filter-column {
                padding: 4px 0 4px 1rem;
                background-color: #fff !important;
                input {
                    min-width: 98%;
                    max-width: 98%;
                }
            }

            .p-datatable-header {
                padding-left: 0;
                padding-top: 0;
                border: unset;
            }
            .filter-btn {
                width: 24px;
                height: 24px;
                background: white;
            }
            tr {
                .redLastListElement {
                    position: fixed !important;
                    display: block !important;
                    z-index: 1042;
                    left: unset !important;
                    transform: translateX(-100%);
                    li:last-child {
                        span {
                            color: red !important;
                        }
                    }
                }
            }
        }
    }
}
.p-new-btn {
    height: 100%;

    .p-button {
        height: 100%;
        align-items: flex-end;
    }
}
::v-deep .p-datatable .p-datatable-tbody > tr > td,
::v-deep .p-datatable .p-datatable-thead > tr > th {
    padding: 0.7rem 1rem;
}
::v-deep .p-datatable-thead {
    .p-filter-column {
        padding: 0 !important;
    }
}
::v-deep .p-datatable-scrollable-header {
    overflow: visible;
}
</style>
