<template>
    <DataTable
        v-if="users"
        :value="users"
        class="p-datatable-customers p-datatable-striped user-list"
        :scrollable='true'
        :paginator="true"
        scroll-height='calc(100vh - 350px)'
        :class="{ showFilters: showFilters }"
        :rows="20"
        :rowsPerPageOptions="rowsPerPageOptions"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate=""
        :totalRecords="totalRecords"
        dataKey="id"
        selectionMode="single"
        :selection.sync="selectedUsers"
        :filters="filters"
        stripedRows
        :key="tableKey"
        @row-dblclick='rowClicked'
        resizable-columns
    >
        <!-- //lazy mode
    :lazy="true"
        @page="paginationChange($event)"
        @sort="onSort($event)" -->
        <template #header>
            <div class="table-header p-flex-wrap p-d-flex">
<!--                <div class="p-col-8 p-p-0 p-d-flex p-ai-center">-->
<!--                    <h5 class="p-d-inline p-mb-0 p-pt-0">Пользователи</h5>-->
<!--                </div>-->
                <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
                <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-jc-end table-global-search">
                    <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                        <span class="p-float-label">
                            <InputText type="text" v-model="filters['global']" placeholder="Поиск" />
                        </span>
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                    <div v-if='canEditUser' class="p-p-0 p-new-btn">
                        <Button @click="addUserHandler" class="p-button"> Добавить </Button>
                    </div>
                </div>
            </div>
        </template>
        <template #empty> Пользователи не найдены.</template>
        <template #loading> Список пользователей загружается. Пожалуйста подождите. </template>
        <Column
            header="Логин"
            field="attributes.login"
            :sortable="true"
            header-class='user-header-login table-header'
            body-class='user-body-login table-body'
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate">
                    {{ slotProps.data.attributes.login }}
                </div>
            </template>
            <template #filter v-if='showFilters'>
                <InputText
                    type="text"
                    v-model="filters['attributes.login']"
                    class="p-column-filter p-my-2"
                    placeholder="Логин"
                />
            </template>
        </Column>
        <Column
            header="Имя"
            field="attributes.firstName"
            header-class='user-header-firstname table-header'
            body-class='user-body-firstname table-body'
            :sortable="true"
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate">
                    {{ slotProps.data.attributes.firstName }}
                </div>
            </template>
            <template #filter v-if='showFilters'>
                <InputText
                    type="text"
                    v-model="filters['attributes.firstName']"
                    class="p-column-filter p-my-2"
                    placeholder="Имя"
                />
            </template>
        </Column>
        <Column
            header="Фамилия"
            field="attributes.lastName"
            header-class='user-header-lastname table-header'
            body-class='user-body-lastname table-body'
            :sortable="true"
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate">
                    {{ slotProps.data.attributes.lastName }}
                </div>
            </template>
            <template #filter v-if='showFilters'>
                <InputText
                    type="text"
                    v-model="filters['attributes.lastName']"
                    class="p-column-filter p-my-2"
                    placeholder="Фамилия"
                />
            </template>
        </Column>
        <Column
            header="Е-mail"
            field="attributes.email"
            header-class='user-header-email table-header'
            body-class='user-body-email table-body'
            :sortable="true"
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate">
                    {{ slotProps.data.attributes.email }}
                </div>
            </template>
            <template #filter v-if='showFilters'>
                <InputText
                    type="text"
                    v-model="filters['attributes.email']"
                    class="p-column-filter p-my-2"
                    placeholder="Е-mail"
                />
            </template>
        </Column>
        <Column
            header="Роль"
            field="role"
            header-class='user-header-role table-header'
            body-class='user-body-role table-body'
            :sortable="true"
        >
            <!--            <template #body="slotProps">-->
            <!--                &lt;!&ndash; slotProps.data.relationships.role.data &ndash;&gt;-->
            <!--                {{ slotProps.data.relationships.role.data}}-->
            <!--            </template>-->
            <template #filter v-if='showFilters'>
                <InputText
                    type="text"
                    v-model="filters['role']"
                    class="p-column-filter p-my-2"
                    placeholder="Роль"
                />
            </template>
        </Column>
        <Column
            header="Организация"
            field="organization"
            header-class='user-header-organization table-header'
            body-class='user-body-organization table-body'
            :sortable="true"
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate">
                    {{ slotProps.data.organization }}
                </div>
            </template>
            <template #filter v-if='showFilters'>
                <InputText
                    type="text"
                    v-model="filters['organization']"
                    class="p-column-filter p-my-2"
                    placeholder="Организация"
                />
            </template>
        </Column>
        <!-- <Column header="" headerClass="" :headerStyle="showNewUser">
            <template #body="slotProps">
                <span v-if="slotProps.data.new" class="p-button p-button-warning newUser not-pointer">Новый</span>
            </template>
        </Column> -->
        <Column
            header="Статус"
            header-class='user-header-status table-header'
            body-class='user-body-status table-body'
            :sortable="true"
            field="status"
            filter-match-mode='equals'
        >
            <template #body="slotProps">
                <span :style="`background: ${statusColors[slotProps.data.status]}; padding: 4px; border-radius: 4px;`">
                    {{ slotProps.data.status }}
                </span>
            </template>
            <template #filter v-if='showFilters'>
                <Dropdown
                    v-model="filters['status']"
                    :options="userStatuses"
                    placeholder="Статус"
                    option-value='label'
                    option-label='label'
                    class="p-column-filter"
                    :showClear="true"
                    @click.stop
                >
                </Dropdown>
            </template>
        </Column>
        <Column
            header=""
            header-class='user-header-actions p-text-center'
            header-style='width: 52px;'
            body-class='user-body-actions'
            body-style="width: 52px; text-align: center;"
        >
            <template v-if='accessibleItems(listMenuItems).length' #body="slotProps">
                <div
                    class="p-panel-header-icon p-link"
                    @click.stop="toggleRowMenu($event, slotProps)"
                >
                    <span class="pi pi-ellipsis-h"></span>
                </div>
                <Menu
                    class="redLastListElement"
                    :ref="`listMenu${slotProps.data.id}`"
                    :model="accessibleItems(listMenuItems)"
                    :popup="true"
                    :baseZIndex='10'
                />
                <!--                <Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text" @click.stop="editRole(slotProps.data.id)" />-->
                <!--                <Button v-if="!guardRoles.find((role) => slotProps.data.id == role)" icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text p-ml-1" @click="removeRole(slotProps.data.id)" />-->
            </template>

            <template #header>
                <Button
                    icon="pi pi-filter"
                    :class="filterClasses"
                    class="p-button-rounded p-button-outlined filter-btn"
                    @click="showFilters = !showFilters"
                />
            </template>
        </Column>
        <!--        <Column headerStyle="width: 7rem" bodyStyle="text-align: right; padding: 0.5rem 1rem;">-->
        <!--            <template #body="slotProps">-->
        <!--                <Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text" @click="$router.push(`/users/${slotProps.data.id}`)" v-tooltip.top="'Редактировать'" />-->
        <!--                <Button icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text p-ml-1" @click="removeUser(slotProps.data.id)" v-tooltip.top="'Удалить'" />-->
        <!--            </template>-->
        <!--        </Column>-->
        <template #paginatorLeft>
            <div class='p-d-flex'>
                <!--                <Button @click="downloadCSV" label="СКАЧАТЬ CSV" class="p-button-text p-mr-2" />-->
                <!--                <Button @click="downloadXLS" label="СКАЧАТЬ XLS" class="p-button-text p-button-secondary" />-->
            </div>
        </template>
    </DataTable>
</template>

<script>
import { requestToastHandler } from '@/main/mixins';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { autorizationAnotherUser } from '@/api/auth';
import { deleteUser, getUsers } from '@/api/user';
import { USER_STATUS_NAMES, USER_STATUSES } from '@/constants/users';
import { USER_PERMISSIONS_MAP } from '@/constants/common';

const {
    userWriteOwn,
    userWriteAll,
    userImpersonate
} = USER_PERMISSIONS_MAP

export default {
    name: 'Users',
    mixins: [requestToastHandler],
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
        orgId: {
            type: [String, Number],
            default: ''
        }
    },
    emits: ['loadingChange'],
    data() {
        this.userStatuses = USER_STATUSES
        this.statusColors = {}
        USER_STATUSES.forEach(({ label, bgColor }) => this.statusColors[label] = bgColor)
        return {
            editUserId: null,
            selectedUsers: null,
            showFilters: false,
            filters: {},
            filter: {},
            users: [],
            userIncluded: [],
            tableKey: 1,
            currentPage: 1,
            rowCount: 20,
            listMenuItems: [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editUser()
                    },
                    permissionAccess: [
                        userWriteAll,
                        userWriteOwn
                    ]
                },
                {
                    label: 'Авторизоваться',
                    icon: 'pi pi-user',
                    command: () => {
                        this.authAnotherUser(this.editUserId)
                    },
                    class: 'user-impersonate',
                    permissionAccess: [ userImpersonate ]
                },
                {
                    label: 'Удалить',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.$root.$emit('showAcceptDeleteDialog', {
                            acceptAction: this.removeUser
                        })

                    },
                    class: 'remove-row',
                    permissionAccess: [
                        userWriteAll,
                        userWriteOwn
                    ]
                },
            ],

            totalRecords: null,
        }
    },
    methods: {
        ...mapMutations('auth', ['clearAccessData', 'setAccessData']),
        ...mapActions('auth', [
            'getMe',
            'getNotifications',
            'authAnotherUser'
        ]),
        addUserHandler() {
            this.$router.push(`/users/new/${this.orgId}`)
        },
        async authAnotherUser() {
            try {
                const result = await autorizationAnotherUser(this.editUserId);
                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }

                this.clearAccessData();
                this.setAccessData(result.attributes);
                await this.getMe();
                await this.getNotifications();
            } catch (error) {
                this.$requestError(error.message);
                return;
            }

            this.$emit('loadingChange', false);
            this.$router.push('/');
        },
        rowClicked({ data }) {
            this.editUserId = data.id
            this.editUser()
        },
        toggleRowMenu(event, slotProps) {
            const { data: { id } } = slotProps
            // Close previous popup
            if (id !== this.editUserId) {
                this.$refs[`listMenu${this.editUserId}`] && this.$refs[`listMenu${this.editUserId}`].hide(event)
            }
            this.editUserId = id
            // Open new popup
            this.$refs[`listMenu${id}`].toggle(event)
        },
        editUser() {
            if (!this.canEditUser) return
            this.$router.push(`/users/edit/${this.editUserId}`);
        },
        userRole(user) {
            let roleTitle = 'нет роли';
            if (!('relationships' in user)) return roleTitle;
            let roleData = user.relationships.role.data;

            if (roleData != null) {
                let role = this.userIncluded.find((role) => role.id === roleData.id);
                roleTitle = role.attributes.name;
            }
            return roleTitle;
        },
        userOrganization(user) {
            let organizationTitle = 'Организация не привязана';
            let organizationData = user.relationships.organization.data;
            if (organizationData != null) {
                let organization = this.userIncluded.find((organization) => organization.id === organizationData.id);
                organizationTitle = organization.attributes.shortName;
            }
            // console.log('this.userIncluded', this.userIncluded);
            return organizationTitle;
        },
        async getUsers() {
            try {
                this.$emit('loadingChange', true);
                const { data: users, included, meta } = await getUsers({
                    filter: {
                        '$or': [
                            { 'organization.id': this.orgId },
                            { 'organization.parentId': this.orgId },
                        ]
                    },
                    include: true
                });
                this.userIncluded = included;
                this.users = users.map((user) => {
                    let status
                    const { active, banned } = user.attributes
                    if (banned) status = USER_STATUS_NAMES.ARCHIVE
                    else if (active) status = USER_STATUS_NAMES.ACTIVE
                    else status = USER_STATUS_NAMES.NEW
                    user.status = status
                    user.role = this.userRole(user);
                    user.organization = this.userOrganization(user);
                    return user;
                });
                this.totalRecords = meta.pagination.total;
                this.$emit('loadingChange', false);
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange', false);
            }
        },
        async removeUser() {
            this.$emit('loadingChange', true);
            try {
                const result = await deleteUser(this.editUserId);
                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }
                this.users = this.users.filter((user) => user.id !== this.editUserId);
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },
        activeChange(id) {
            console.log('Сменили активность у юзера', id);
        },

        isActiveStatus(data) {
            return data.data.attributes.active
        }
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        ...mapGetters('auth', [
            'accessibleItems'
        ]),
        // haveNewUser() {
        //     return !!this.users.find((user) => user.new);
        // },
        // showNewUser() {
        //     return this.haveNewUser ? 'width: 6rem' : 'width: 0rem';
        // },
        canEditUser() {
            return this.accessibleItems(this.listMenuItems).length
        },
        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },
    },

    async mounted() {
        await this.getUsers();
    },
};
</script>

<style lang='scss' scoped>
.user-list {
    .table-global-search {
        margin-top: unset!important;
    }
    ::v-deep {
        .p-dropdown-label {
            line-height: unset;
            display: flex;
            align-items: center;
        }
        .user-header {
            &-login {
                width: 7.8125vw;
            }
            &-firstname {
                width: 6.25vw;
            }
            &-lastname {
                width: 7.8125vw;
            }
            &-email {
                width: 6.25vw;
            }
            &-role {
                width: 9.375vw;
            }
            &-organization {
                width: 17.1875vw;
            }
            &-status {
                width: 6.25vw;
            }
        }
        .user-body {
            &-login {
                width: 7.8125vw;
            }
            &-firstname {
                width: 6.25vw;
            }
            &-lastname {
                width: 7.8125vw;
            }
            &-email {
                width: 6.25vw;
            }
            &-role {
                width: 9.375vw;
            }
            &-organization {
                width: 17.1875vw;
            }
            &-status {
                width: 6.25vw;
                .status-value {
                    background: #FFBBB3;
                    height: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: min-content;
                    padding: 0 4px;
                    border-radius: 4px;
                    line-height: 1rem;
                    &.active {
                        background: #B3FFBB;
                    }
                }
            }
        }
    }
}
</style>
