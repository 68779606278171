import { ORG_FIELDS } from '@/constants/organizations';

export default function(data = {}, parentId = null, relationships = {}) {
    ORG_FIELDS.forEach(field => {
        this[field] = data[field] || '';
        if (parentId && ['legalAddress', 'contactName', 'contactPhone', 'contactEmail'].includes(field)) {
            this[field] = '';
        }
    });

    this.id = data.id || '';
    this.status = data.status || 'new';
    this.parent = { id: parentId, type: 'organization' };
    this.subordinateTo = relationships.subordinateTo?.data || { id: '', type: 'organization-structure' };
    this.location = relationships.location?.data || { id: '', type: 'organization-location' };
    this.district = relationships.district?.data || { id: '', type: 'organization-location' };
    this.type = relationships.type?.data || { id: '', type: 'organization-type' };
    this.tags = relationships.tags || [];
    this.active = false;
}
