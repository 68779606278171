<template>
    <DataTable
        :value="historyList"
        class="p-datatable-customers p-datatable-striped user-list"
        :scrollable="true"
        :paginator="true"
        :lazy="true"
        scroll-height="calc(100vh - 350px)"
        :rows="pageSize"
        :rowsPerPageOptions="rowsPerPageOptions"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate=""
        :totalRecords="totalRecords"
        dataKey="id"
        selectionMode="single"
        stripedRows
        resizable-columns
        @page="onPage"
    >
        <template #header>
            <div class="table-header p-flex-wrap p-d-flex">
                <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
            </div>
        </template>
        <template #empty>Изменения не найдены.</template>
        <template #loading>Список изменений загружается. Пожалуйста, подождите.</template>
        <Column
            header="Период начала"
            field="activeFrom"
            header-class='user-header-login table-header'
            body-class='user-body-login table-body'
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate">
                    {{ slotProps.data.activeFrom | date }}
                </div>
            </template>
        </Column>
        <Column
            header="Период окончания"
            field="activeUntil"
            header-class='user-header-login table-header'
            body-class='user-body-login table-body'
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate">
                    {{ slotProps.data.activeUntil ? (slotProps.data.activeUntil) : new Date() | date }}
                </div>
            </template>
        </Column>
        <Column
            header="Кем изменено"
            header-class='user-header-status table-header'
            body-class='user-body-status table-body'
            field="createdBy"
        >
            <template #body="slotProps">
                <div v-show="slotProps.data.createdBy.firstName" class="p-text-nowrap p-text-truncate" :title="`${ slotProps.data.createdBy.firstName } ${ slotProps.data.createdBy.lastName }`">
                    {{ slotProps.data.createdBy.firstName }} {{ slotProps.data.createdBy.lastName }}
                </div>
            </template>
        </Column>
        <Column
            header="Полное наименование"
            field="fullName"
            header-class='user-header-firstname table-header'
            body-class='user-body-firstname table-body'
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate" :title="slotProps.data.fullName">
                    {{ slotProps.data.fullName }}
                </div>
            </template>
        </Column>
        <Column
            header="Краткое наименование"
            field="shortName"
            header-class='user-header-lastname table-header'
            body-class='user-body-lastname table-body'
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate" :title="slotProps.data.shortName">
                    {{ slotProps.data.shortName }}
                </div>
            </template>
        </Column>
        <Column
            header="Организация"
            field="type"
            header-class='user-header-role table-header'
            body-class='user-body-role table-body'
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate">
                    {{ slotProps.data.parent.id ? 'Филиал' : 'Головная' }}
                </div>
            </template>
        </Column>
        <Column
            header="Головная организация"
            field="parent"
            header-class='user-header-organization table-header'
            body-class='user-body-organization table-body'
        >
            <template #body="slotProps">
                <div class="p-text-nowrap p-text-truncate">
                    {{ slotProps.data.parent.id ? slotProps.data.parent.shortName : '' }}
                </div>
            </template>
        </Column>
        <Column
            header="Подчинение"
            header-class='user-header-status table-header'
            body-class='user-body-status table-body'
            field="subordinateTo.name"
        />
        <Column
            header="Расположение"
            header-class='user-header-status table-header'
            body-class='user-body-status table-body'
            field="location.name"
        />
        <Column
            header="Округ"
            header-class='user-header-status table-header'
            body-class='user-body-status table-body'
            field="district.name"
        />
        <Column
            header="Тип"
            header-class='user-header-status table-header'
            body-class='user-body-status table-body'
            field="type.name"
        />
        <template #paginatorLeft>
            <div class='p-d-flex'></div>
        </template>
    </DataTable>
</template>

<script>
import { requestToastHandler } from '@/main/mixins';
import { jsonApiListParser } from '@/main/utils/common';
import { mapGetters } from 'vuex';
import { getOrganizationHistory } from '@/api/organization/organizationHistory';

export default {
    name: 'History',

    mixins: [ requestToastHandler ],

    emits: [ 'loadingChange' ],

    props: {
        orgId: {
            type: String,
            require: true,
        }
    },

    data() {
        return {
            historyList: [],
            totalRecords: null,
            currentPage: 1,
            pageSize: 20
        };
    },

    async mounted() {
        await this.getHistory();
    },

    methods: {
        onPage(event) {
            setTimeout(() => {
                this.currentPage = event.page + 1;
                this.pageSize = event.rows;
                this.getHistory();
            }, 500);
        },

        async getHistory() {
            this.$emit('loadingChange', true);

            try {
                const { data, included, meta } = await getOrganizationHistory(this.orgId, {
                    page: this.currentPage,
                    pageSize: this.pageSize
                });
                this.historyList = jsonApiListParser(data, included);
                this.totalRecords = meta.pagination.total;
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        }
    },

    computed: {
        ...mapGetters(['rowsPerPageOptions']),
    }
}
</script>
