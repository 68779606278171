<template>
    <DataTable
        :value="branches"
        :scrollable='true'
        :paginator="true"
        scroll-height='calc(100vh - 450px)'
        class="p-datatable-customers p-datatable-striped branch-list"
        :class="{ showFilters }"
        :rows="20"
        :rowsPerPageOptions="rowsPerPageOptions"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate=""
        :totalRecords="totalRecords"
        selectionMode="single"
        :filters="filters"
        resizable-columns
        @row-dblclick='rowClicked'
        filterMode='lenient'
        striped-rows
    >
        <!-- lazy mode options -->
        <!-- :lazy="true"
        @page="paginationChange($event)"
        @sort="onSort($event)" -->
        <template #header>
            <div class="table-header p-flex-wrap p-d-flex">
<!--                <div class="p-col-8 p-p-0 p-d-flex p-ai-center">-->
<!--                    <h5 class="p-d-inline p-mb-0 p-pt-0">Список организаций</h5>-->
<!--                </div>-->
                <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
                <div class="p-col-7 p-p-0 p-d-flex p-jc-end p-ai-center table-global-search">
                    <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                        <span class="p-float-label">
                            <InputText type="text" v-model="filters['global']" placeholder="Поиск" />
                        </span>
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                    <div v-if='canEditBranch' class="p-p-0 p-new-btn">
                        <Button @click="addBranchHandler" class="p-button"> Добавить </Button>
                    </div>
                </div>
            </div>
        </template>
        <template #empty> Организации не найдены</template>
        <template #loading> Загрузка списка организаций. Пожалуйста подождите</template>
        <Column
            header="Название"
            header-class='org-header-name table-header'
            body-class='p-text-nowrap p-text-truncate org-body-name table-body'
            :sortable="true"
            field="shortName"
            :expander='true'
        >
            <template #body='slotProps'>
                <span :title='slotProps.data.fullName'>{{ slotProps.data.shortName }}</span>
            </template>
            <template #filter v-if='showFilters'>
                <InputText
                    v-show='showFilters'
                    type="text"
                    v-model="filters['shortName']"
                    class="p-column-filter p-my-2"
                    placeholder="Поиск по названию"
                />
            </template>
        </Column>
        <Column
            header="Подчинение"
            header-class='org-header-subordinate table-header'
            body-class='p-text-nowrap p-text-truncate org-body-subordinate table-body'
            :sortable="true"
            field="subordinateTo.name"
        >
            <template #body='slotProps'>
                <span :title='slotProps.data.subordinateTo.name'>{{ slotProps.data.subordinateTo.name }}</span>
            </template>
            <template #filter v-if='showFilters'>
                <InputText
                    v-show='showFilters'
                    type="text"
                    v-model="filters['subordinateTo.name']"
                    class="p-column-filter"
                    placeholder="Подчинение"
                />
            </template>
        </Column>
        <Column
            header="Расположение"
            header-class='org-header-location table-header'
            body-class='p-text-nowrap p-text-truncate org-body-location table-body'
            :sortable="true"
            field="location.name"
        >
            <template #body='slotProps'>
                <span :title='slotProps.data.location.name'>{{ slotProps.data.location.name }}</span>
            </template>
            <template #filter v-if='showFilters'>
                <InputText
                    v-show='showFilters'
                    type="text"
                    v-model="filters['location.name']"
                    class="p-column-filter"
                    placeholder="Расположение"
                />
            </template>
        </Column>
        <Column
            header="Тип"
            header-class='org-header-type table-header'
            body-class='p-text-nowrap p-text-truncate org-body-type table-body'
            :sortable="true"
            field="type.name"
        >
            <template #body='slotProps'>
                <span :title='slotProps.data.type.name'>{{ slotProps.data.type.name }}</span>
            </template>
            <template #filter v-if='showFilters'>
                <InputText
                    v-show='showFilters'
                    type="text"
                    v-model="filters['type.name']"
                    class="p-column-filter"
                    placeholder="Тип"
                />
            </template>
        </Column>
        <Column
            header="Статус"
            header-class='org-header-status table-header'
            body-class='p-text-nowrap p-text-truncate org-body-status table-body'
            :sortable="true"
            field="status"
        >
            <template #body='slotProps'>
                <span
                    :title='getStatus(slotProps).title'
                    :style='`background: ${getStatus(slotProps).bgColor}; padding: 4px; border-radius: 4px;`'
                >
                    {{ getStatus(slotProps).label }}
                </span>
            </template>
            <template #filter v-if='showFilters'>
                <Dropdown
                    v-show='showFilters'
                    v-model="filters['status']"
                    :options="orgStatuses"
                    placeholder="Статус"
                    class="p-column-filter"
                    option-value='value'
                    option-label='label'
                    :showClear="true"
                    @click.stop
                >
<!--                    <template #option="slotProps">-->
<!--                        <span :class="'customer-badge status-' + slotProps.option">{{ slotProps.option }}</span>-->
<!--                    </template>-->
                </Dropdown>
            </template>
        </Column>
        <Column
            header=""
            header-class='p-text-center'
            body-style="position: relative; width: 52px; text-align: center;"
            header-style='width: 52px;'
        >
            <template #header>
                <Button
                    icon="pi pi-filter"
                    :class="filterClasses"
                    class="p-button-rounded p-button-outlined filter-btn"
                    @click="showFilters = !showFilters"
                />
            </template>
            <template v-if='accessibleItems(listMenuItems).length' #body="slotProps">
                <div
                    class="p-panel-header-icon p-link"
                    @click.stop="toggleRowMenu($event, slotProps)"
                >
                    <span class="pi pi-ellipsis-h"></span>
                </div>
                <Menu
                    class="redLastListElement"
                    :ref="`listMenu${slotProps.data.id}`"
                    :model="accessibleItems(listMenuItems)"
                    :popup="true"
                    :baseZIndex='10'
                />
            </template>
            <!--            <template #body="slotProps">-->
            <!--                <Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text" @click="$router.push(`/organizations/${slotProps.data.id}`)" />-->
            <!--                <Button icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text p-ml-1" @click="removeOrganization(slotProps.data.id)" />-->
            <!--            </template>-->
        </Column>
        <template #paginatorLeft>
            <div class='p-col-6'></div>
            <!--            <Button @click="downloadCSV" label="СКАЧАТЬ CSV" class="p-button-text p-mr-2" />-->
            <!--            <Button @click="downloadXLS" label="СКАЧАТЬ XLS" class="p-button-text p-button-secondary" />-->
        </template>
    </DataTable>
</template>

<script>
import { deleteOrganization, getOrganizations } from '@/api/organization';
import { ORG_STATUSES } from '@/constants/organizations';
import { requestToastHandler } from '@/main/mixins';
import { mapGetters } from 'vuex';
import { jsonApiListParser } from '@/main/utils/common';
import { USER_PERMISSIONS_MAP, PAGE_SIZE } from '@/constants/common';

const {
    organizationWrite,
    organizationWriteAll,
    organizationReadAll,
    organizationRead,
} = USER_PERMISSIONS_MAP

export default {
    name: 'Branches',
    mixins: [requestToastHandler],
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        orgId: {
            type: [String, Number],
            default: ''
        }
    },
    data() {
        this.orgStatuses = ORG_STATUSES
        return {
            filters: {},
            showFilters: false,
            branches: [],
            editOrgId: '',
            totalRecords: null,
            currentPage: 1,
            statuses: ['активная', 'не активная'],
            listMenuItems: [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editItem()
                    },
                    permissionAccess: [
                        organizationWriteAll,
                        organizationWrite,
                        organizationRead,
                        organizationReadAll
                    ]
                },
                // {
                //     label: 'Удалить',
                //     icon: 'pi pi-trash',
                //     command: () => {
                //         // this.showDeletePopUp(this.currentPeriodId);
                //         // this.removeOrganization()
                //         this.$root.$emit('showAcceptDeleteDialog', {
                //             acceptAction: this.removeOrganization
                //         })
                //     },
                //     class: 'remove-row',
                //     permissionAccess: [
                //         organizationWrite,
                //         organizationWriteAll
                //     ]
                // },
            ],
        }
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        ...mapGetters('auth', [
            'accessibleItems'
        ]),
        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },
        canEditBranch() {
            return this.accessibleItems(this.listMenuItems).length
        }
    },
    async created() {
        this.$emit('loadingChange', true)
        await this.getBranches()
        this.$emit('loadingChange')
    },
    methods: {
        async getBranches(orgId = this.orgId) {
            try {
                const { included, organizations: branches, meta: { pagination: { total }} } = await getOrganizations({
                    pageSize: PAGE_SIZE,
                    relationShip: true,
                    filter: {
                        parentId: orgId
                    }
                })
                this.branches = jsonApiListParser(branches, included)
                this.totalRecords = total
                console.log('BRANCHES', this.branches);
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        rowClicked({ data: { id } }) {
            // console.log(data, key);
            this.editOrgId = id
            this.editItem()
        },
        editItem() {
            if (!this.canEditBranch) return
            this.$router.push(`/organizations/edit/${this.editOrgId}`);
        },
        getStatus (slotProps) {
            const status = this.orgStatuses.find(item =>  item.value === slotProps.data.status)
            return status || {}
        },
        async toggleRowMenu(event, slotProps) {
            const { data: { id } } = slotProps
            // Close previous popup
            if (id !== this.editOrgId) {
                this.$refs[`listMenu${this.editOrgId}`] && this.$refs[`listMenu${this.editOrgId}`].hide(event)
            }
            this.editOrgId = id
            const menuEl = this.$refs[`listMenu${id}`]
            // Open new popup
            menuEl.toggle(event)
            await this.$nextTick()
            const { y } = event
            const { $el } = menuEl
            if ($el.style) $el.style.top = `${y}px`
        },
        addBranchHandler() {
            this.$router.push(`/organizations/new/${this.orgId}`)
        },
        async removeOrganization() {
            try {
                this.$emit('loadingChange', true);
                const response = await deleteOrganization(this.editOrgId);
                if (response.message) {
                    this.$requestError(response.message);
                    return;
                }
                this.branches = this.branches.filter((organization) => organization.id !== this.editOrgId);
                this.$emit('loadingChange', false);
                // this.$toast.add({
                //     severity: 'success',
                //     summary: 'Организация удалена',
                //     life: 2500,
                // });
            } catch (error) {
                this.$emit('loadingChange', false);
                this.$requestError(error.message);
            }
        },
    }
};
</script>

<style lang='scss' scoped>
.branch-list {
    .table-global-search {
        margin-top: unset!important;
    }
    ::v-deep {
        .p-dropdown-label {
            line-height: unset;
            display: flex;
            align-items: center;
        }
    }
}
</style>
