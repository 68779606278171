<template>
    <div>
        <div v-show="editMode" class="p-grid p-mx-0">
            <div class="p-col-12 p-md-12 p-d-flex p-ai-center p-jc-start p-pl-0">
                <div class="p-card-body id-item p-mr-2 p-d-flex p-ai-center p-py-2 p-px-3">
                    <div class="p-card-subtitle p-mb-0">
                        ID: <span class="black">{{ formData.incrementId }}</span>
                    </div>
                </div>
            </div>
        </div>
        <form class="edit-form p-card">
            <Dialog :visible.sync="leaveDialog" header="" position="center" :modal="true" :closeOnEscape="true" :closable="false">
                <div class="p-dialog-content p-ai-center p-d-flex">
                    <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"></i>
                    <span>Вы вносили данные. Вы уверены, что хотите покинуть страницу без сохранения данных?</span>
                </div>

                <template #footer>
                    <Button class="p-button p-button-text p-button-danger" @click="clearAndLeave">
                        <span class="pi pi-times p-button-icon p-button-icon-left"></span>
                        <span class="p-button-label">Удалить данные и покинуть</span>
                    </Button>
                    <Button class="p-button p-component p-button-text" type="button" autofocus @click="leaveDialog = false">
                        <span class="pi pi-check p-button-icon-left"></span>
                        <span class="p-button-label">Вернуться к заполнению</span>
                        <span class="p-ink"></span>
                    </Button>
                </template>
            </Dialog>
            <div class="form-header">
                <div class="p-field-radiobutton p-mb-0">
                    <RadioButton
                        id='isHeadOrganizationTrue'
                        name='headOrg'
                        :value='organizationMainTypes.head'
                        :disabled='!canEditOrg'
                        v-model='organizationType'
                    />
                    <label for="isHeadOrganizationTrue">Головная</label>
                </div>
                <div class="p-field-radiobutton p-mb-0">
                    <RadioButton
                        id='isHeadOrganizationFalse'
                        name='headOrg'
                        :value='organizationMainTypes.filial'
                        :disabled='!canEditOrg'
                        v-model='organizationType'
                    />
                    <label for="isHeadOrganizationFalse">Филиал</label>
                </div>
                <div v-if="!isHeadOrganization" class="p-field org-select p-mb-0">
                    <Dropdown
                        v-model="formData.parent.id"
                        option-value="id"
                        placeholder="Выберите головную организацию*"
                        :options="allOrgs"
                        :class="v$.formData.parent.id.$invalid && submitted ? 'p-error' : ''"
                        :disabled="!canEditOrg"
                        @filter='debouncedInputFilter'
                        option-label="attributes.shortName"
                        data-key="id"
                        filter
                        @change="loadOrganizationDetails"
                    />
                    <small v-show='submitted' v-for='error in showErrors(v$.formData.parent.id)' :key='error.$uid'
                           class='p-error'><small>{{ error.$message }}</small><br></small>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid p-mx-0 form-fields">
                <div class="p-col-6 left-col p-p-0">
                    <div class="p-field">
                        <label for="shortName">Краткое наименование</label>
                        <input-text
                            id='shortName'
                            v-model='v$.formData.shortName.$model'
                            placeholder='Введите краткое наименование'
                            :class="v$.formData.shortName.$invalid && submitted ? 'p-error' : ''"
                            :disabled='!canEditOrg'
                        />
                        <small v-show='submitted' v-for='error in showErrors(v$.formData.shortName)' :key='error.$uid'
                               class='p-error'><small>{{ error.$message }}</small><br></small>
                    </div>
                    <div class="p-field">
                        <label for="name">Полное наименование*</label>
                        <input-text
                            id='name'
                            v-model='v$.formData.fullName.$model'
                            placeholder='Введите полное наименование' :autoResize='true' rows='3' cols='30'
                            :class="v$.formData.fullName.$invalid && submitted ? 'p-error' : ''"
                            :disabled='!canEditOrg'
                        />
                        <small v-show='submitted' v-for='error in showErrors(v$.formData.fullName)' :key='error.$uid'
                               class='p-error'><small>{{ error.$message }}</small><br></small>
                    </div>
                    <!--                    <div class="p-field">-->
                    <!--                        <label for="headOrg">Организация</label>-->
                    <!--                        <AutoComplete-->
                    <!--                            :disabled="formData.isHeadOrganization"-->
                    <!--                            id="headOrg"-->
                    <!--                            v-model="headOrgTitle"-->
                    <!--                            :suggestions="filteredOrganizations"-->
                    <!--                            @complete="searchOrganization($event)"-->
                    <!--                            @item-select="selectOrganization($event)"-->
                    <!--                            @blur="checkHeadOrganization"-->
                    <!--                            :dropdown="true"-->
                    <!--                            field="name"-->
                    <!--                            forceSelection-->
                    <!--                            placeholder="Найти по имени"-->
                    <!--                            :class="headOrgError ? 'p-error' : ''"-->
                    <!--                        >-->
                    <!--                            &lt;!&ndash; @update:modelValue="searchHeadOrg()" &ndash;&gt;-->
                    <!--                            <template #item="slotProps">-->
                    <!--                                <div class="country-item">-->
                    <!--                                    <div>{{ slotProps.item.attributes.fullName }}</div>-->
                    <!--                                </div>-->
                    <!--                            </template>-->
                    <!--                        </AutoComplete>-->
                    <!--                        <small class="p-error">{{ headOrgError }}</small>-->
                    <!--                    </div>-->
                    <div class="p-field">
                        <label for="orgTypes">Тип организации*</label>
                        <Dropdown
                            v-model="formData.type.id"
                            :disabled='!canEditOrg'
                            :options="orgTypes"
                            option-label="name"
                            option-value="id"
                            placeholder="Выберите из списка"
                            data-key="id"
                            filter
                            :class='{"p-error": showErrors(v$.formData.type.id).length && submitted }'
                        />
                        <!--                        <AutoComplete-->
                        <!--                            id="orgTypes"-->
                        <!--                            v-model="v$.formData.orgType.$model"-->
                        <!--                            :suggestions="filteredOrgTypes"-->
                        <!--                            @complete="searchOrgType($event)"-->
                        <!--                            @blur="checkOrganizationType"-->
                        <!--                            :dropdown="true"-->
                        <!--                            field="name"-->
                        <!--                            forceSelection-->
                        <!--                            placeholder="Найти по имени"-->
                        <!--                            :class="v$.formData.orgType.$invalid && submitted ? 'p-error' : ''"-->
                        <!--                        >-->
                        <!--                            <template #item="slotProps">-->
                        <!--                                <div class="country-item">-->
                        <!--                                    <div>{{ slotProps.item.name }}</div>-->
                        <!--                                </div>-->
                        <!--                            </template>-->
                        <!--                        </AutoComplete>-->
                        <small v-show='submitted' v-for='error in showErrors(v$.formData.type.id)' :key='error.$uid'
                               class='p-error'><small>{{ error.$message }}</small><br></small>
                    </div>
                    <div class="p-field">
                        <label for="subordination">Подчинение*</label>
                        <Dropdown
                            v-model="formData.subordinateTo.id"
                            :options="subordination"
                            :disabled='!canEditOrg'
                            option-label="name"
                            option-value="id"
                            placeholder="Выберите из списка"
                            data-key="id"
                            filter
                            :class='{"p-error": showErrors(v$.formData.subordinateTo.id).length && submitted }'
                        />
                        <!--                        <AutoComplete-->
                        <!--                            id="subordination"-->
                        <!--                            v-model="v$.formData.subordination.$model"-->
                        <!--                            :suggestions="filteredSubordination"-->
                        <!--                            @complete="searchSubordination($event)"-->
                        <!--                            @blur="checkOrganizationSubordination"-->
                        <!--                            :dropdown="true"-->
                        <!--                            field="name"-->
                        <!--                            forceSelection-->
                        <!--                            placeholder="Найти по имени"-->
                        <!--                            :class="v$.formData.subordination.$invalid && submitted ? 'p-error' : ''"-->
                        <!--                        >-->
                        <!--                            <template #item="slotProps">-->
                        <!--                                <div class="country-item">-->
                        <!--                                    <div>{{ slotProps.item.name }}</div>-->
                        <!--                                </div>-->
                        <!--                            </template>-->
                        <!--                        </AutoComplete>-->
                        <small v-show='submitted' v-for='error in showErrors(v$.formData.subordinateTo.id)'
                               :key='error.$uid' class='p-error'><small>{{ error.$message }}</small><br></small>
                    </div>
                    <div class="p-field">
                        <label for="location">Расположение*</label>
                        <Dropdown
                            v-model="formData.location.id"
                            :options="locations"
                            :disabled='!canEditOrg'
                            option-label="name"
                            option-value="id"
                            placeholder="Выберите из списка"
                            data-key="id"
                            filter
                            :class='{"p-error": showErrors(v$.formData.location.id).length && submitted }'
                        />
                        <small v-show='submitted' v-for='error in showErrors(v$.formData.location)' :key='error.$uid'
                               class='p-error'><small>{{ error.$message }}</small><br></small>
                    </div>
                    <div class="p-field">
                        <label for="location">Округ*</label>
                        <Dropdown
                            v-model="formData.district.id"
                            :options="locations"
                            :disabled='!canEditOrg'
                            option-label="name"
                            option-value="id"
                            placeholder="Выберите из списка"
                            data-key="id"
                            filter
                            :class='{"p-error": showErrors(v$.formData.district.id).length && submitted }'
                        />
                        <small v-show='submitted' v-for='error in showErrors(v$.formData.district)' :key='error.$uid'
                               class='p-error'><small>{{ error.$message }}</small><br></small>
                    </div>
                    <div class="p-field">
                        <label for="abbreviationName">Аббревиатура</label>
                        <input-text
                            id='abbreviationName'
                            v-model='v$.formData.abbreviationName.$model'
                            placeholder='Введите аббревиатуру'
                            :class="v$.formData.abbreviationName.$invalid && submitted ? 'p-error' : ''"
                            :disabled='!canEditOrg'
                        />
                        <small v-show='submitted' v-for='error in showErrors(v$.formData.abbreviationName)'
                               :key='error.$uid' class='p-error'><small>{{ error.$message }}</small><br></small>
                    </div>
                    <div class="p-field">
                        <label for="status">Статус</label>
                        <Dropdown
                            v-model='formData.active'
                            :options='statuses'
                            :disabled='!canEditOrg'
                            option-label='label'
                            option-value='value'
                            placeholder='Выберите из списка'
                            data-key='value'
                        />
                    </div>
                    <!--                    <div class="p-field">-->
                    <!--                        <label for="tags">Теги</label>-->
                    <!--                        <AutoComplete-->
                    <!--                            v-model='formData.tags'-->
                    <!--                            field='id'-->
                    <!--                            :suggestions='filteredTags'-->
                    <!--                            dropdown-->
                    <!--                            multiple-->
                    <!--                            @complete="searchTags($event)"-->
                    <!--                        />-->
                    <!--                    </div>-->
                </div>
                <div class="p-col-6 right-col p-p-0">
                    <!--                    <div class="p-field">-->
                    <!--                        <label for="codeId">Внешний код ID</label>-->
                    <!--                        <input-text-->
                    <!--                            id="codeId"-->
                    <!--                            placeholder=""-->
                    <!--                        />-->
                    <!--                    </div>-->
                    <div class="p-field">
                        <label for="inn">ИНН*</label>
                        <input-text
                            id='inn'
                            v-model='v$.formData.inn.$model'
                            placeholder='ИНН'
                            :class="v$.formData.inn.$invalid && submitted ? 'p-error' : ''"
                            :disabled='formData.parent.id || !canEditOrg'
                            @blur="getOrgInfoFromEgrul"
                            @keypress.enter.stop="getOrgInfoFromEgrul"
                        />
                        <small v-show='submitted' v-for='error in showErrors(v$.formData.inn)' :key='error.$uid'
                               class='p-error p-mr-2'><small>{{ error.$message }}</small><br></small>
                        <!--  -->
                    </div>
                    <div class="p-field-combo p-field-combo-2">
                        <div class="p-field">
                            <label>КПП</label>
                            <input-text
                                id="legalKpp"
                                v-model="v$.formData.legalKpp.$model"
                                placeholder="КПП"
                                :class="v$.formData.legalKpp.$invalid && submitted ? 'p-error' : ''"
                                :disabled='formData.parent.id || !canEditOrg'
                            />
                            <small v-show="submitted" v-for="error in showErrors(v$.formData.legalKpp)"
                                   :key="error.$uid" class="p-error">
                                <small>{{ error.$message }}</small><br>
                            </small>
                        </div>
                        <div class="p-field">
                            <label>ОГРН</label>
                            <input-text
                                id="legalOgrn"
                                v-model="v$.formData.legalOgrn.$model"
                                placeholder="ОГРН"
                                :class="v$.formData.legalOgrn.$invalid && submitted ? 'p-error' : ''"
                                :disabled='formData.parent.id || !canEditOrg'
                            />
                            <small v-show="submitted" v-for="error in showErrors(v$.formData.legalOgrn)"
                                   :key="error.$uid" class="p-error p-mr-2">
                                <small>{{ error.$message }}</small><br>
                            </small>
                        </div>
                    </div>
                    <div class="p-field-combo p-field-combo-3">
                        <div class="p-field">
                            <label for="legalOkpo">ОКПО</label>
                            <input-text
                                id='legalOkpo'
                                v-model='v$.formData.legalOkpo.$model'
                                placeholder='ОКПО'
                                :class="v$.formData.legalOkpo.$invalid && submitted ? 'p-error' : ''"
                                :disabled='formData.parent.id || !canEditOrg'
                            />
                            <small v-show='submitted' v-for='error in showErrors(v$.formData.legalOkpo)'
                                   :key='error.$uid' class='p-error p-mr-2'><small>{{ error.$message
                                }}</small><br></small>
                        </div>
                        <div class="p-field">
                            <label for="legalOkved">ОКВЭД</label>
                            <input-text
                                id='legalOkved'
                                v-model='v$.formData.legalOkved.$model'
                                placeholder='ОКВЭД'
                                :class="v$.formData.legalOkved.$invalid && submitted ? 'p-error' : ''"
                                :disabled='formData.parent.id || !canEditOrg'
                            />
                            <small v-show='submitted' v-for='error in showErrors(v$.formData.legalOkved)'
                                   :key='error.$uid' class='p-error p-mr-2'><small>{{ error.$message
                                }}</small><br></small>
                        </div>
                        <div class="p-field">
                            <label for="legalOkdp">ОКПД</label>
                            <input-text
                                id='legalOkdp'
                                v-model='v$.formData.legalOkdp.$model'
                                placeholder='ОКПД'
                                :class="v$.formData.legalOkdp.$invalid && submitted ? 'p-error' : ''"
                                :disabled='formData.parent.id || !canEditOrg'
                            />
                            <small v-show='submitted' v-for='error in showErrors(v$.formData.legalOkdp)'
                                   :key='error.$uid' class='p-error p-mr-2'><small>{{ error.$message
                                }}</small><br></small>
                        </div>
                    </div>
                    <div class="p-field-combo p-field-combo-3">
                        <div class="p-field">
                            <label for="legalOkonh">ОКОНХ</label>
                            <input-text
                                id='legalOkonh'
                                v-model='v$.formData.legalOkonh.$model'
                                placeholder='ОКОНХ'
                                :class="v$.formData.legalOkonh.$invalid && submitted ? 'p-error' : ''"
                                :disabled='formData.parent.id || !canEditOrg'
                            />
                            <small v-show='submitted' v-for='error in showErrors(v$.formData.legalOkonh)'
                                   :key='error.$uid' class='p-error p-mr-2'><small>{{ error.$message
                                }}</small><br></small>
                        </div>
                        <div class="p-field">
                            <label for="legalOkato">ОКАТО</label>
                            <input-text
                                id='legalOkato'
                                v-model='v$.formData.legalOkato.$model'
                                placeholder='ОКАТО'
                                :class="v$.formData.legalOkato.$invalid && submitted ? 'p-error' : ''"
                                :disabled='formData.parent.id || !canEditOrg'
                            />
                            <small v-show='submitted' v-for='error in showErrors(v$.formData.legalOkato)'
                                   :key='error.$uid' class='p-error p-mr-2'><small>{{ error.$message
                                }}</small><br></small>
                        </div>
                        <div class="p-field">
                            <label for="legalOkogu">ОКОГУ</label>
                            <input-text
                                id='legalOkogu'
                                v-model='v$.formData.legalOkogu.$model'
                                placeholder='ОКОГУ'
                                :class="v$.formData.legalOkogu.$invalid && submitted ? 'p-error' : ''"
                                :disabled='formData.parent.id || !canEditOrg'
                            />
                            <small v-show='submitted' v-for='error in showErrors(v$.formData.legalOkogu)'
                                   :key='error.$uid' class='p-error p-mr-2'><small>{{ error.$message
                                }}</small><br></small>
                        </div>
                    </div>
                    <div class="p-field">
                        <label for="mailingAddress">Почтовый адрес*</label>
                        <input-text
                            id='mailingAddress'
                            v-model='v$.formData.legalAddress.$model'
                            placeholder='Введите почтовый адрес'
                            :class="v$.formData.legalAddress.$invalid && submitted ? 'p-error' : ''"
                            :disabled='!canEditOrg'
                        />
                        <small v-show='submitted' v-for='error in showErrors(v$.formData.legalAddress)'
                               :key='error.$uid' class='p-error'><small>{{ error.$message }}</small><br></small>
                    </div>
                    <div class="p-field">
                        <label for="contactFullName">Контактное лицо</label>
                        <input-text
                            id='contactFullName'
                            v-model='v$.formData.contactName.$model'
                            placeholder='Введите ФИО'
                            :class="v$.formData.contactName.$invalid && submitted ? 'p-error' : ''"
                            :disabled='!canEditOrg'
                        />
                        <small v-show='submitted' v-for='error in showErrors(v$.formData.contactName)' :key='error.$uid'
                               class='p-error'><small>{{ error.$message }}</small><br></small>
                    </div>
                    <div class="p-field-combo p-field-combo-2">
                        <div class="p-field">
                            <label for="contactEmail">E-mail контактного лица</label>
                            <input-text
                                id='contactEmail'
                                v-model='v$.formData.contactEmail.$model'
                                placeholder='Введите e-mail'
                                :class="v$.formData.contactEmail.$invalid && submitted ? 'p-error' : ''"
                                :disabled='!canEditOrg'
                            />
                            <small v-show='submitted' v-for='error in showErrors(v$.formData.contactEmail)'
                                   :key='error.$uid' class='p-error'><small>{{ error.$message }}</small><br></small>
                        </div>
                        <div class="p-field">
                            <label for="contactTelephone">Телефон контактного лица</label>
                            <input-text
                                id='contactTelephone'
                                v-model='v$.formData.contactPhone.$model'
                                placeholder='Введите телефон'
                                :class="v$.formData.contactPhone.$invalid && submitted ? 'p-error' : ''"
                                :disabled='!canEditOrg'
                            />
                            <small v-show='submitted' v-for='error in showErrors(v$.formData.contactPhone)'
                                   :key='error.$uid' class='p-error p-mr-2'><small>{{ error.$message
                                }}</small><br></small>
                        </div>
                    </div>
                    <!--                    <div class="p-field-checkbox p-col-12" v-if="formData.ownRequisites.shown">-->
                    <!--                        <Checkbox :binary="true" id="ownRequisites" v-model="formData.ownRequisites.value" />-->
                    <!--                        <label for="ownRequisites"> Имеет свои собственные реквизиты </label>-->
                    <!--                    </div>-->
                </div>
            </div>
        </form>
        <div class="edit-form__actions">
            <Button v-if='canEditOrg' class="p-button" label="Сохранить" @click="onSubmit" />
            <Button class="p-button-outlined" label="Отменить" @click="cancelAction" />
        </div>
    </div>
</template>

<script>
import { email, helpers, maxLength, minLength, numeric, required } from '@vuelidate/validators';
import { ORG_FIELD_NAMES, ORG_FIELDS, ORG_FORM_TITLES } from '@/constants/organizations';
import { getOrganizationById, getOrganizations, getOrgRequisitesByInn, patchOrganization, registerNewOrganization } from '@/api/organization';
import { getOrgsLocations } from '@/api/organization/orgsLocations';
import { getOrgsSubordinations } from '@/api/organization/orgsSubordinations';
import { getOrgsTypes } from '@/api/organization/orgsTypes';
import { useVuelidate } from '@vuelidate/core';
import { requestToastHandler } from '@/main/mixins';
import Organization from '@/models/Organization';
import SubmitJsonApi from '@/models/SubmitJsonApi';
import { USER_PERMISSIONS_MAP } from '@/constants/common';
import { mapGetters } from 'vuex';
import { pushOrUpdate } from '@/utils/common';

const {
    SHORT_NAME,
    FULL_NAME,
    ABBREVIATION_NAME,
    INN,
    LEGAL_KPP,
    LEGAL_OGRN,
    LEGAL_ADDRESS,
    LEGAL_OKPO,
    LEGAL_OKVED,
    LEGAL_OKONH,
    LEGAL_OKATO,
    LEGAL_OKOGU,
    LEGAL_OKDP,
    CONTACT_NAME,
    CONTACT_PHONE,
    CONTACT_EMAIL,
} = ORG_FIELD_NAMES;


const {
    organizationWriteAll,
    organizationWriteOwn,
    organizationWrite,
} = USER_PERMISSIONS_MAP

export default {
    name: 'Params',
    mixins: [requestToastHandler],
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        // const standart = (minLen = 5) => ({
        //     required: { ...required, $message: 'Поле обязательно к заполнению' },
        //     minLength: { ...minLength(minLen), $message: `Значение не должно быть короче ${minLen} символов` },
        // });
        // const telephoneMask = () => /^((8|\+7)[- ]?)?(\(?\d{3,4}\)?[- ]?)?[\d\- ]{7,8}$/.test(this.formData.phone);
        const telephoneMask = (val) => val.length > 0 ? (/^[0-9-+)( ]+$/.test(val) && val.length > 1 && val.length < 256) : true;
        const parentRequired = () => {
            return this.isHeadOrganization ? true : !!this.formData.parent.id;
        };
        const KPPLength = (val) => {
            if (val) {
                return val.length > 0 ? val.length === 9 : true;
            } else {
                return true;
            }
        }
        const INNLength = (val) => {
            if (val) {
                return val.length > 0 ? val.length === 10 : true;
            } else {
                return true;
            }
        }
        const OGRNLength = (val) => {
            if (val) {
                return val.length > 0 ? val.length === 13 : true;
            } else {
                return true;
            }
        }
        const OKVEDLength = (val) => {
            if (val) {
                return val.length > 0 ? (val.length > 3) : true;
            } else {
                return true;
            }
        };
        const OKPOLength = (val) => {
            if (val) {
                return val.length > 0 ? (val.length === 8 || val.length === 10) : true;
            } else {
                return true;
            }
        }
        const OKONHLength = (val) => {
            if (val) {
                return val.length > 0 ? val.length === 5 : true;
            } else {
                return true;
            }
        }
        const OKOGULength = (val) => {
            if (val) {
                return val.length > 0 ? val.length === 7 : true;
            } else {
                return true;
            }
        }
        return {
            formData: {
                parent: { id: { parentRequired: helpers.withMessage('Поле обязательно к заполнению', parentRequired) } },
                type: { id: { required: { ...required, $message: 'Поле обязательно к заполнению' } } },
                subordinateTo: { id: { required: { ...required, $message: 'Поле обязательно к заполнению' } } },
                location: { id: { required: { ...required, $message: 'Поле обязательно к заполнению' } } },
                district: { id: { required: { ...required, $message: 'Поле обязательно к заполнению' } } },
                [FULL_NAME]: { required: { ...required, $message: 'Поле обязательно к заполнению' } },
                [SHORT_NAME]: { maxLength: { ...maxLength(255), $message: 'Максимальная длина 255 символов' } },
                [ABBREVIATION_NAME]: { maxLength: { ...maxLength(128), $message: 'Максимальная длина 128 символов' } },
                [LEGAL_ADDRESS]: {
                    maxLength: {
                        ...maxLength(255),
                        $message: 'Максимальная длина 255 символов',
                    },
                },
                [CONTACT_NAME]: {},
                [CONTACT_EMAIL]: { email: { ...email, $message: 'Введите корректный e-mail' } },
                [CONTACT_PHONE]: { telephoneMask: helpers.withMessage('Неверный формат телефона', telephoneMask) },
                active: {},
                ownRequisites: {
                    value: {},
                },
                [INN]: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                    numeric: { ...numeric, $message: 'Допустимы только цифры' },
                    INNLength: helpers.withMessage(
                        'Длина 10 символов',
                        INNLength
                    ),
                },
                [LEGAL_KPP]: {
                    KPPLength: helpers.withMessage(
                        'Длина 9 знаков (цифр или заглавных букв латинского алфавита от A до Z)',
                        KPPLength
                    ),
                },
                [LEGAL_OGRN]: {
                    numeric: { ...numeric, $message: 'Допустимы только цифры' },
                    OGRNLength: helpers.withMessage(
                        'Длина 13 цифр',
                        OGRNLength
                    ),
                },
                [LEGAL_ADDRESS]: { required: { ...required, $message: 'Поле обязательно к заполнению' } },
                [LEGAL_OKPO]: {
                    numeric: { ...numeric, $message: 'Допустимы только цифры' },
                    OKPOLength: helpers.withMessage('Длина 8 или 10 цифр', OKPOLength),
                },
                [LEGAL_OKVED]: {
                    OKVEDLength: helpers.withMessage('Длина от 4 символов', OKVEDLength),
                },
                [LEGAL_OKDP]: {
                    minLength: { ...minLength(7), $message: 'Длина не менее 7 символов' },
                    maxLength: { ...maxLength(255), $message: 'Максимальная длина 255 символов' }
                },
                [LEGAL_OKONH]: {
                    numeric: { ...numeric, $message: 'Допустимы только цифры' },
                    OKONHLength: helpers.withMessage('Длина 5 цифр', OKONHLength),
                },
                [LEGAL_OKATO]: {
                    numeric: { ...numeric, $message: 'Допустимы только цифры' },
                },
                [LEGAL_OKOGU]: {
                    numeric: { ...numeric, $message: 'Допустимы только цифры' },
                    OKOGULength: helpers.withMessage('Длина 7 цифр', OKOGULength),
                },
            },
        };
    },
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
        orgId: {
            type: [String, Number],
            default: '',
        },
        mainOrgId: {
            type: [String, Number],
            default: '',
        },
    },
    computed: {
        ...mapGetters('auth', ['userPermissionsObject']),
        isHeadOrganization() {
            return this.organizationType === this.organizationMainTypes.head;
        },
        canEditOrg() {
            // TODO подумать на тему возможности попасть на страницу с чужой организацией
            return [
                organizationWriteOwn,
                organizationWriteAll,
                organizationWrite
            ].some(p => this.userPermissionsObject[p])
        },
        allOrgs() {
            if (!this.parentOrg) return this.organizations
            pushOrUpdate(this.organizations, this.parentOrg)
            return this.organizations
        }
    },
    // async activated() {
    //     // if (this.dataLoaded) this.$emit('loadingChange')
    //     this.$emit('loadingChange', true)
    //     await this.getData()
    //     await this.handleEditData()
    //     this.$emit('loadingChange')
    // },
    data() {
        this.isBranchCreating = !!this.mainOrgId;
        this.editTitle = ORG_FORM_TITLES.EDIT;
        this.createTitle = ORG_FORM_TITLES.CREATE;
        this.statuses = [
            { value: true, label: 'Активная' },
            { value: false, label: 'Не активная' },
        ];
        this.organizationMainTypes = {
            head: 'head',
            filial: 'filial',
        };
        return {
            filterInputTimeout: null,
            loadingOrgs: false,
            dataLoaded: false,
            organizationType: this.organizationMainTypes.head,
            leaveRoute: '/',
            leaveDialog: false,
            headOrgTitle: '',
            formData: new Organization(),
            parentOrg: null,
            organizations: [],
            filteredOrganizations: [],
            locations: [],
            filteredLocations: [],
            subordination: [],
            filteredSubordination: [],
            orgTypes: [],
            filteredOrgTypes: [],
            submitted: false,
            headOrgError: '',
            filteredTags: [],
        };
    },
    async created() {
        try {
            this.$emit('loadingChange', true);
            await this.handleEditData();
            await this.getData();
        } catch (error) {
            this.$requestError(error.message);
        } finally {
            this.$emit('loadingChange');
        }
        this.$emit('change-data', false);
    },
    methods: {
        async handleEditData() {
            // Создание филиала
            if (this.isBranchCreating) {
                this.organizationType = this.organizationMainTypes.filial;
                this.formData.parent = { id: this.mainOrgId, type: 'organization' };
                const { data } = await getOrganizationById(this.mainOrgId);
                this.parentOrg = data;
                this.$emit('is-branch');
                this.loadOrganizationDetails();
            }
            if (this.editMode) {
                try {
                    const {
                        data: { attributes: orgData, relationships },
                        included
                    } = await getOrganizationById(this.orgId);
                    ORG_FIELDS.forEach((key) => {
                        this.formData[key] = orgData[key] || '';
                    });
                    Object.keys(relationships).forEach((prop) => {
                        const propData = relationships[prop].data;
                        if (propData && !Array.isArray(propData)) {
                            this.formData[prop].id = propData.id;
                        }
                    });
                    if (this.formData.parent.id) {
                        this.organizationType = this.organizationMainTypes.filial;
                        this.parentOrg = included.find(i => i.id === this.formData.parent.id)
                        this.$emit('is-branch');
                        this.loadOrganizationDetails();
                    }
                    this.formData.active = orgData.active;
                    this.formData.incrementId = orgData.incrementId;
                } catch(error) {
                    this.$requestError(error.message);
                }
            }
        },
        cancelAction() {
            window.history.back();
        },
        searchTags(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredTags = [...this.filteredTags];
                } else {
                    this.filteredTags = this.filteredTags.filter((tag) => {
                        return tag.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        checkHeadOrganization() {
            if (!this.formData.headOrg.id) this.headOrgTitle = '';
        },
        checkOrganizationType() {
            if (typeof this.formData.orgType == 'string') this.formData.orgType = null;
        },
        checkOrganizationSubordination() {
            if (typeof this.formData.subordination == 'string') this.formData.subordination = null;
        },
        checkOrganizationLocation() {
            if (typeof this.formData.location == 'string') this.formData.location = null;
        },
        async selectOrganization($event) {
            this.headOrgError = '';
            const fields = ['inn', 'legalOkpo', 'legalOkved', 'legalOkdp', 'legalOkonh', 'legalOkato', 'legalOkogu'];

            this.headOrgTitle = $event.value.attributes.fullName;
            this.formData.headOrg.value = $event.value;
            this.formData.headOrg.id = $event.value.id;
            fields.forEach((field) => (this.formData[field] = $event.value.attributes[field]));
            // await getOrganizationById($event.value.id);
            //попытаться получить реквизиты и подставить их
        },

        async getOrgInfoFromEgrul() {
            try {
                if (this.formData.inn?.length === 10) {
                    this.$emit('loadingChange', true);
                    const result = await getOrgRequisitesByInn(this.formData.inn);
                    if (!result) return;
                    (this.formData.legalKpp = result.attributes.kpp),
                        (this.formData.legalOgrn = result.attributes.ogrn),
                        (this.formData.legalOkpo = result.attributes.okpo),
                        (this.formData.legalOkved = result.attributes.okved),
                        (this.formData.legalOkdp = result.attributes?.okdp),
                        (this.formData.legalOkonh = result.attributes?.okonh),
                        (this.formData.legalOkato = result.attributes.okato),
                        (this.formData.legalOkogu = result.attributes.okogu),
                        this.$emit('loadingChange', false);
                }
            } catch (error) {
                this.$emit('loadingChange', false);
                this.$requestError(error.message);
            }
        },
        editIsHeadOrganization() {
            this.headOrgError = '';
            // if (this.formData.isHeadOrganization) {
            //     this.formData.ownRequisites.shown = true;
            //     this.formData.ownRequisites.value = false;
            //     return;
            // }

            this.formData.headOrg.value = false;
            // this.formData.ownRequisites.shown = false;
            // this.formData.ownRequisites.value = true;
        },
        async getData() {

            try {
                await Promise.all([
                    getOrgsLocations().then(({ locations }) => {
                        this.locations = locations
                    }),
                    getOrgsSubordinations().then(({ subordinations }) => {
                        this.subordination = subordinations
                    }),
                    getOrgsTypes().then(({ types }) => {
                        this.orgTypes = types
                    }),
                    getOrganizations({
                        pageSize: 20,
                        filter: {
                            parentId: null
                        }
                    }).then(({ organizations }) => {
                        this.organizations = organizations
                        if (this.parentOrg) pushOrUpdate(this.organizations, this.parentOrg)
                    })
                ])
                // const result = await getOrganizations({ pageSize: 20 });
                // this.organizations = result.organizations;
                // const allTagsDictionaries = await tagsReferenceList({});
                // const lastTagsDictionary = allTagsDictionaries[allTagsDictionaries.length - 1];
                // const tags = lastTagsDictionary && lastTagsDictionary.attributes.tags;
                // this.filteredTags = tags.map((i) => ({ id: i, type: 'organization-tags' }));
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        clearAndLeave() {
            this.v$.$anyDirty = false;
            this.$router.push(this.leaveRoute);
        },
        // searchOrganization(event) {
        //     this.formData.headOrg.id = null;
        //     if (!event.query.trim().length) {
        //         this.filteredOrganizations = [...this.organizations];
        //         return;
        //     }
        //     this.filteredOrganizations = this.organizations.filter((organization) => organization.attributes.fullName.toLowerCase().indexOf(event.query.toLowerCase()) >= 0);
        // },
        searchOrgType(event) {
            if (!event.query.trim().length) {
                this.filteredOrgTypes = [...this.orgTypes];
            } else {
                this.filteredOrgTypes = this.orgTypes.filter((orgType) => orgType.name.toLowerCase().indexOf(event.query.toLowerCase()) >= 0);
            }
        },
        searchSubordination(event) {
            if (!event.query.trim().length) {
                this.filteredSubordination = [...this.subordination];
            } else {
                this.filteredSubordination = this.subordination.filter((subordinationOne) => subordinationOne.name.toLowerCase().indexOf(event.query.toLowerCase()) >= 0);
            }
        },
        searchLocation(event) {
            if (!event.query.trim().length) {
                this.filteredLocations = [...this.locations];
            } else {
                this.filteredLocations = this.locations.filter((location) => location.name.toLowerCase().indexOf(event.query.toLowerCase()) >= 0);
            }
        },
        noEditField() {
            return this.editMode;
        },

        async onSubmit() {
            this.submitted = true;
            // if (!this.formData.isHeadOrganization) {
            //     this.formData.headOrg.value ? (this.headOrgError = '') : (this.headOrgError = 'Выберите головную организацию');
            //     return;
            // }
            //
            if (!this.v$.$invalid && !this.headOrgError) {
                const dataToServer = new SubmitJsonApi(this.formData, 'organization');
                // if (this.editMode) delete dataToServer.data.attributes.inn
                try {
                    this.$emit('loadingChange', true);
                    const { data: result } = this.editMode ? await patchOrganization(dataToServer, this.orgId) : await registerNewOrganization(dataToServer);
                    if (result.message) {
                        this.$requestError(result.message);
                        return;
                    }

                    this.v$.$anyDirty = false;
                    this.$emit('change-data', false);
                    if (!this.editMode) {
                        await this.$router.push(`/organizations/edit/${ result.id }`);
                    }
                } catch (error) {
                    this.$requestError(error.message);
                    throw new Error(error.message)
                } finally {
                    this.$emit('loadingChange');
                }
            }
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
        debouncedInputFilter ({ value }) {
            clearTimeout(this.filterInputTimeout)
            this.filterInputTimeout = setTimeout(() => {
                this.updateOrgList(value)
            }, 500)
        },

        async loadOrganizationDetails() {
            this.$emit('loadingChange', true);
            try {
                const { data: { attributes: organizationData, relationships } } = await getOrganizationById(this.formData.parent.id);
                if (!this.editMode) {
                    this.formData = new Organization(organizationData, this.formData.parent.id, relationships);
                } else {
                    ['inn', 'legalKpp', 'legalOgrn', 'legalOkpo', 'legalOkved', 'legalOkonh', 'legalOkato', 'legalOkogu', 'legalOkdp'].forEach((field) => {
                        this.formData[field] = organizationData[field];
                    });
                }
            } catch(error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        async updateOrgList(val) {
            if (this.loadingOrgs) return
            this.$emit('loadingChange', !(val && val.length < 3))
            if (val) {
                if (val.length < 3) return
                try {
                    this.loadingOrgs = true
                    const { organizations } = await getOrganizations({
                        filter: {
                            'name.shortName': { '$like': val },
                            parentId: null
                        }
                    })
                    this.organizations = organizations;
                } catch (e) {
                    console.log(e);
                } finally {
                    this.$emit('loadingChange', false)
                    this.loadingOrgs = false
                }
                return
            }
            const { organizations } = await getOrganizations({
                pageSize: 20,
                filter: {
                    parentId: null
                }
            });
            this.organizations = organizations
            this.$emit('loadingChange', false)
        }
    },
    watch: {
        formData: {
            deep: true,
            handler() {
                this.$emit('change-data', true)
            }
        },
        isOwnRequisites() {
            this.toggleEgrulField();
        },
        organizationType(to) {
            if (to === 'head') {
                this.parentOrg = null
                this.formData.parent = { id: null, type: 'organization' };
            }
        },
    },
    // beforeRouteLeave(to, from, next) {
    //     if (this.v$.$anyDirty) {
    //         this.leaveRoute = to.fullPath;
    //         this.leaveDialog = true;
    //         return;
    //     }
    //     next();
    // },
};
</script>

<style scoped lang="scss">
.edit-form {
    .form-header {
        display: grid !important;
        grid-template-columns: min-content min-content 1fr;
        min-height: 34px;
        label {
            font-size: 14px;
            line-height: 1.5;
        }
        & > .p-field-radiobutton {
            &:first-child {
                margin-right: 4.6875vw;
            }
        }
        .org-select {
            justify-self: flex-end;
            margin-left: 3.125vw;
            min-width: 52.8125vw;
            max-width: 52.8125vw;
            .p-dropdown {
                height: 34px;
                width: 100%;
            }
        }
        .p-field {
            ::v-deep {
                .p-dropdown-panel {
                    width: 100%;
                }
            }
        }
    }
    .form-fields {
        .left-col {
            padding-right: 8px !important;
        }
        .right-col {
            padding-left: 8px !important;
        }
        margin-top: 16px;
        .p-field {
            ::v-deep {
                .p-dropdown-panel {
                    width: 100%;
                }
            }
            label {
                font-size: 13px;
                margin-bottom: 4px;
            }
            margin-bottom: 16px;
            &-combo {
                display: grid;
                grid-gap: 16px;
                &-2 {
                    grid-template-columns: repeat(2, 1fr);
                }
                &-3 {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
    }
}
</style>
